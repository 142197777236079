// src/stores/EventStore.ts

import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';

export interface Location {
  id: number;
  name: string;
}

export interface Event {
  id: number;
  title: string;
  location: Location;
  start_date: string; // Format: YYYY-MM-DD
  start_time: string; // Format: HH:MM
  end_date: string;   // Format: YYYY-MM-DD
  end_time: string;   // Format: HH:MM
  description: string;
  link: string;
  button_text: string;
  is_active: boolean;
}

class EventStore {
  events: Event[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Fetch active events from the backend API.
   * @param locationName - The name of the location to filter events.
   */
  fetchEvents = async (locationName?: string) => {
    this.loading = true;
    this.error = null;
    try {
      const params = locationName ? { location: locationName } : {};
      const response = await API.get<Event[]>('/api/active-events/', { params });
      runInAction(() => {
        this.events = response.data;
        this.loading = false;
      });
    } catch (err: any) {
      let errorMessage = 'Failed to load events.';
      if (err.response) {
        // Server responded with a status other than 2xx
        errorMessage = err.response.data.detail || errorMessage;
      } else if (err.request) {
        // Request was made but no response received
        errorMessage = 'No response from server.';
      } else {
        // Something else caused the error
        errorMessage = err.message;
      }

      runInAction(() => {
        this.error = errorMessage;
        this.loading = false;
      });
    }
  };

  /**
   * Refresh events by refetching from the API.
   * @param locationName - The name of the location to filter events.
   */
  refreshEvents = (locationName?: string) => {
    this.fetchEvents(locationName);
  };
}

const eventStore = new EventStore();
export default eventStore;