import React from 'react';

interface MenuImagesProps {
  images: string[];
}

const MenuImages: React.FC<MenuImagesProps> = ({ images }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 h-auto sm:h-[50vh]">
      {images.map((image, index) => (
        <div key={index} className="w-full sm:w-1/3 h-[33vh] sm:h-full">
          <img src={image} alt={`Trifold ${index + 1}`} className="object-cover w-full h-full rounded-lg" />
        </div>
      ))}
    </div>
  );
};

export default MenuImages;