import React from 'react';

const HeroSection: React.FC = () => {
  return (
    <section 
      className="relative h-[90vh] bg-cover bg-center flex flex-col justify-between items-center text-white"
      style={{ backgroundImage: `url(https://holdrens-bucket.s3.amazonaws.com/steak-center.webp)` }}
    >
      <div className="relative w-full h-full flex flex-col items-center">
        <h1 
          className="text-6xl sm:text-6xl md:text-8xl font-paperboard absolute top-1/4 text-center mx-4 md:[word-spacing:-1rem]"
        >
          Welcome to
          <br/>
          Holdren's
          <br />
          Steaks & Seafood
        </h1>
        <div 
          className="absolute bottom-12 text-center mx-4 bg-black bg-opacity-50 px-4 py-2 rounded-md"
        >
          <p className="text-lg sm:text-2xl md:text-3xl font-paperboard font-semibold leading-snug">
            Experience the timeless charm of our award-winning steakhouse.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;