// src/components/MenuItems/WeeklySpecials.tsx
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import menuItemStore from '../../../stores/MenuItemStore';
import SpecialCard from './SpecialCard';
import { MenuItem as MenuItemType } from '../../../types';
import { motion } from 'framer-motion';

interface WeeklySpecialsProps {
  categoryId: number;
}

const WeeklySpecials: React.FC<WeeklySpecialsProps> = observer(({ categoryId }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = ref.current;

    // If data is already loaded, no need to observe
    if (menuItemStore.getMenuItemsByCategory(categoryId).length > 0) {
      return;
    }

    const observerInstance = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          menuItemStore.fetchMenuItemsForCategories([categoryId]).catch((error) => {
            console.error(`Error loading category ${categoryId}:`, error);
            // Optionally handle error (e.g., display a notification)
          });
          observerInstance.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observerInstance.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observerInstance.unobserve(currentRef);
      }
    };
  }, [categoryId]);

  // If data is not loaded and not loading, the component hasn't been observed yet
  if (menuItemStore.getMenuItemsByCategory(categoryId).length === 0 && !menuItemStore.loading[categoryId] && !menuItemStore.error[categoryId]) {
    return <div ref={ref}>Loading...</div>;
  }

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div className="text-red-500">Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);

  const groupBySubcategory = (items: MenuItemType[]) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const groupedItems: Record<string, MenuItemType[]> = {};

    items.forEach(item => {
      const subcategoryName = item.subcategory?.name || 'Other';
      if (!groupedItems[subcategoryName]) {
        groupedItems[subcategoryName] = [];
      }
      groupedItems[subcategoryName].push(item);
    });

    return daysOfWeek
      .map(day => ({
        name: day,
        items: groupedItems[day] || [],
      }))
      .filter(group => group.items.length > 0);
  };

  const groupedSpecials = groupBySubcategory(menuItems);

  return (
    <div className="flex flex-wrap justify-center gap-8 p-4">
      {groupedSpecials.map((subcategory, index) => (
        <motion.div
          key={index}
          className="w-full sm:w-1/2 lg:w-1/3"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <SpecialCard subcategoryName={subcategory.name} items={subcategory.items} />
        </motion.div>
      ))}
    </div>
  );
});

export default WeeklySpecials;