// src/components/MenuFunctions/ScrollObserver.tsx
import React, { useEffect, useRef } from 'react';

interface ScrollObserverProps {
  onIntersect: (id: number) => void;
  children: React.ReactNode;
  categoryId: number;
}

const ScrollObserver: React.FC<ScrollObserverProps> = ({ onIntersect, children, categoryId }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const hasIntersectedRef = useRef(false);

  useEffect(() => {
    const theRef = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasIntersectedRef.current) {
            hasIntersectedRef.current = true;
            onIntersect(categoryId);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (theRef) {
      observer.observe(theRef);
    }

    return () => {
      if (theRef) {
        observer.unobserve(theRef);
      }
    };
  }, [categoryId, onIntersect]);

  return <div ref={ref}>{children}</div>;
};

export default ScrollObserver;