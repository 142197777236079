// src/stores/MenuItemStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';
import { MenuItem } from '../types';

class MenuItemStore {
  menuItemsByCategory: Record<number, MenuItem[]> = {};
  loading: Record<number, boolean> = {};
  error: Record<number, string | null> = {};

  // Internal queue for batching fetch requests
  private pendingCategoryIds: Set<number> = new Set();
  private debounceTimer: NodeJS.Timeout | null = null;
  private readonly DEBOUNCE_DELAY = 50; // milliseconds

  // Arrays to store resolve and reject functions for each promise
  private pendingResolvers: Array<() => void> = [];
  private pendingRejecters: Array<(error: any) => void> = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public method to fetch menu items by category.
   * Internally batches multiple requests within a short timeframe.
   */
  fetchMenuItemsByCategory(categoryId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.menuItemsByCategory[categoryId] && !this.loading[categoryId]) {
        resolve();
        return;
      }

      this.pendingCategoryIds.add(categoryId);
      this.pendingResolvers.push(resolve);
      this.pendingRejecters.push(reject);
      this.enqueueBatchFetch();
    });
  }

  /**
   * Enqueue the batch fetch with debouncing to allow multiple category IDs to be fetched together.
   */
  private enqueueBatchFetch() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const categoriesToFetch = Array.from(this.pendingCategoryIds);
      this.pendingCategoryIds.clear();

      // Capture current resolvers and rejecters
      const currentResolvers = [...this.pendingResolvers];
      const currentRejecters = [...this.pendingRejecters];
      this.pendingResolvers = [];
      this.pendingRejecters = [];

      // Mark categories as loading
      runInAction(() => {
        categoriesToFetch.forEach(id => {
          this.loading[id] = true;
          this.error[id] = null;
        });
      });

      // Perform the batch fetch
      API.get(`/api/menu-items/?categories=${categoriesToFetch.join(',')}`)
        .then(response => {
          runInAction(() => {
            // Assuming response.data is an array of MenuItem objects
            response.data.forEach((item: MenuItem) => {
              const catId = item.category.id;
              if (!this.menuItemsByCategory[catId]) {
                this.menuItemsByCategory[catId] = [];
              }
              this.menuItemsByCategory[catId].push(item);
            });

            // Mark categories as loaded
            categoriesToFetch.forEach(id => {
              this.loading[id] = false;
            });
          });
          // Resolve all pending promises
          currentResolvers.forEach(resolve => resolve());
        })
        .catch(error => {
          runInAction(() => {
            categoriesToFetch.forEach(id => {
              this.error[id] = error.message;
              this.loading[id] = false;
            });
          });
          // Reject all pending promises
          currentRejecters.forEach(reject => reject(error));
        });
    }, this.DEBOUNCE_DELAY);
  }

  /**
   * Fetch menu items for multiple categories at once.
   * Uses the same batching mechanism.
   */
  fetchMenuItemsForCategories(categoryIds: number[]): Promise<void> {
    return Promise.all(categoryIds.map(id => this.fetchMenuItemsByCategory(id))).then(() => {});
  }

  getMenuItemsByCategory(categoryId: number): MenuItem[] {
    return this.menuItemsByCategory[categoryId] || [];
  }
}

const menuItemStore = new MenuItemStore();
export default menuItemStore;