import { makeAutoObservable, runInAction } from "mobx";
import API from "../api/client";
import { Category } from "../types";

class CategoryStore {
  categories: Category[] = [];
  loading: boolean = false;
  error: string | null = null;
  selectedMenu: string = "Santa Barbara Menu";

  constructor() {
    makeAutoObservable(this);
    this.fetchCategoriesByMenu(this.selectedMenu);
  }

  fetchCategories = async () => {
    this.setLoading(true);
    this.setError(null);
    try {
      const response = await API.get<Category[]>("/api/categories/");
      runInAction(() => {
        this.categories = response.data;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  };

  fetchCategoriesByMenu = async (menuName: string) => {
    this.setLoading(true);
    this.setError(null);
    try {
      const response = await API.get<Category[]>("/api/categories/", {
        params: { menu: menuName },
      });
      runInAction(() => {
        this.categories = response.data;
        this.selectedMenu = menuName;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setError = (error: string | null) => {
    this.error = error;
  };

  setSelectedMenu = (menuName: string) => {
    this.selectedMenu = menuName;
    this.fetchCategoriesByMenu(menuName);
  };
}

const categoryStore = new CategoryStore();
export default categoryStore;