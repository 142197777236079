// src/components/Menu.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { reaction as mobxReaction } from 'mobx';
import categoryStore from '../../stores/CategoryStore';
import PdfStore from '../../stores/PdfStore';
import CategoryList from '../common/CategoryList';
import ScrollObserver from './MenuFunctions/ScrollObserver';
import CategorySection from './MenuFunctions/CategorySection';
import HeritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import ElegantLine from '../../assets/images/ElegantLine.svg';
import menuItemStore from '../../stores/MenuItemStore';
import SpecialCategoryHeader from './MenuFunctions/SpecialCategoryHeader';
import SpecialCategoryFooter from './MenuFunctions/SpecialCategoryFooter';

interface MenuProps {
  menuName: string;
  specialCategories: string[];
}

const Menu: React.FC<MenuProps> = observer(({ menuName, specialCategories }) => {
  const [visibleSections, setVisibleSections] = useState<Set<number>>(new Set());
  const [clickedCategoryId, setClickedCategoryId] = useState<number | null>(null);
  const [requiredCategories, setRequiredCategories] = useState<number[]>([]);
  const [isPreloading, setIsPreloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isScrolling = useRef<boolean>(false);

  // Create a ref map for category sections
  const sectionRefs = useRef<Record<number, HTMLDivElement | null>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        await categoryStore.fetchCategoriesByMenu(menuName);
        await PdfStore.fetchPdfs();
      } catch (error: any) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data. Please try again.');
      }
    };

    fetchData();
  }, [menuName]);

  const handleIntersect = (categoryId: number) => {
    if (isScrolling.current) return;

    setVisibleSections(prev => new Set(prev).add(categoryId));
  };

  const handleCategoryClick = async (categoryId: number) => {
    setClickedCategoryId(categoryId);
    setIsPreloading(true);
    setErrorMessage(null);

    try {
      const categoryIndex = categoryStore.categories.findIndex(cat => cat.id === categoryId);
      if (categoryIndex === -1) {
        throw new Error(`Category with id ${categoryId} not found`);
      }

      const categoriesToLoad = categoryStore.categories
        .slice(0, categoryIndex + 2)
        .map(cat => cat.id);

      setRequiredCategories(categoriesToLoad);

      await menuItemStore.fetchMenuItemsForCategories(categoriesToLoad);

      setVisibleSections(prev => new Set([...prev, ...categoriesToLoad]));
    } catch (error: any) {
      console.error('Error preloading categories:', error);
      setErrorMessage('Failed to load sections. Please try again.');
    } finally {
      setIsPreloading(false);
    }
  };

  const handleContentLoaded = useCallback((categoryId: number) => {
    // Implement any post-loading actions if necessary
  }, []);

  useEffect(() => {
    const disposer = mobxReaction(
      () => requiredCategories.map(id => menuItemStore.loading[id]),
      (loadingStates) => {
        if (clickedCategoryId && loadingStates.every(isLoading => !isLoading)) {
          const section = sectionRefs.current[clickedCategoryId];
          if (section) {
            isScrolling.current = true;

            const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - window.innerHeight * 0.15;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });

            setTimeout(() => {
              isScrolling.current = false;
              setClickedCategoryId(null);
            }, 1000);
          }
        }
      }
    );

    return () => {
      disposer();
    };
  }, [clickedCategoryId, requiredCategories]);

  useEffect(() => {
    if (clickedCategoryId !== null && visibleSections.has(clickedCategoryId)) {
      const section = sectionRefs.current[clickedCategoryId];
      if (section && !menuItemStore.loading[clickedCategoryId] && !menuItemStore.error[clickedCategoryId]) {
        requestAnimationFrame(() => {
          isScrolling.current = true;

          const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - window.innerHeight * 0.15;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

          setTimeout(() => {
            isScrolling.current = false;
            setClickedCategoryId(null);
          }, 1000);
        });
      }
    }
  }, [visibleSections, clickedCategoryId]);

  return (
    <div className="mt-8 sm:mt-16 md:mt-24 space-y-12 sm:space-y-16 md:space-y-24">
      <CategoryList menu={menuName} onCategoryClick={handleCategoryClick} />

      {/* Display loading indicator while preloading */}
      {isPreloading && (
        <div className="flex justify-center items-center space-x-2 text-lg sm:text-xl md:text-2xl">
          <svg
            className="animate-spin h-8 w-8 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          <span>Loading sections...</span>
        </div>
      )}

      {/* Display error message if preloading fails */}
      {errorMessage && (
        <div className="text-center text-red-500 text-lg sm:text-xl md:text-2xl">
          {errorMessage}
        </div>
      )}

      {/* Render each category section */}
      {categoryStore.categories.map((category, index) => {
        const isSpecial = specialCategories.includes(category.name);
        const isOdd = index % 2 === 1;
        const SvgLeft = isOdd ? ElegantLine : HeritageInfinity;
        const SvgRight = isOdd ? ElegantLine : HeritageInfinity;
        const svgSize =
          SvgLeft === HeritageInfinity
            ? 'w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24'
            : 'w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 xl:w-48';

        return (
          <ScrollObserver
            key={category.id}
            onIntersect={handleIntersect}
            categoryId={category.id}
          >
            <div
              id={`category-${category.id}`}
              data-category-id={category.id}
              className="mb-12 sm:mb-16 md:mb-24"
              ref={el => (sectionRefs.current[category.id] = el)}
            >
              {/* Header */}
              {isSpecial ? (
                <SpecialCategoryHeader category={category} svgSize={svgSize} />
              ) : (
                <div className="text-center mb-4 sm:mb-6 md:mb-8 relative flex flex-col sm:flex-row items-center justify-center">
                  <img
                    src={SvgLeft}
                    alt="Left Decorative SVG"
                    className={`mb-4 sm:mb-0 ${svgSize}`}
                    aria-hidden="true"
                  />
                  <h2 className="text-3xl sm:text-4xl md:text-5xl font-paperboard mx-4">
                    {category.name}
                  </h2>
                  <img
                    src={SvgRight}
                    alt="Right Decorative SVG"
                    className={`mt-4 sm:mt-0 ${svgSize}`}
                    aria-hidden="true"
                  />
                </div>
              )}

              {/* Notes for Non-Special Categories */}
              {!isSpecial && category.notes && category.notes.length > 0 && (
                <div className="flex flex-col items-center text-center mb-6">
                  {category.notes.map((note, index) => (
                    <p key={index} className="text-lg sm:text-xl font-lora mb-2 text-balance">
                      {note}
                    </p>
                  ))}
                </div>
              )}

              {/* Category Content */}
              <CategorySection
                category={category}
                isVisible={visibleSections.has(category.id)}
              />

              {/* Footer for Special Categories */}
              {isSpecial && (
                <SpecialCategoryFooter svgSize="w-24 h-8 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-44 lg:h-44 xl:w-56 xl:h-56" />
              )}
            </div>
          </ScrollObserver>
        );
      })}
    </div>
  );
});

export default React.memo(Menu);