// src/stores/ReservationsStore.ts

import { makeAutoObservable } from 'mobx';
import API from '../api/client';

interface ReservationFormData {
  date: string;
  hour: string; // e.g., "12 PM"
  minute: string; // e.g., "00"
  guests: number;
  venue: 'Private Patio' | 'Red Room';
  name: string;
  email: string;
  phone: string;
  message: string;
}

class ReservationStore {
  formData: ReservationFormData = {
    date: '',
    hour: '',
    minute: '',
    guests: 1,
    venue: 'Private Patio',
    name: '',
    email: '',
    phone: '',
    message: '',
  };
  isLoading: boolean = false;
  currentStep: number = 1; // 1: Details, 2: Contact

  constructor() {
    makeAutoObservable(this);
  }

  // Setters for form fields
  setDate(date: string) {
    this.formData.date = date;
  }

  setHour(hour: string) {
    this.formData.hour = hour;
  }

  setMinute(minute: string) {
    this.formData.minute = minute;
  }

  setGuests(guests: number) {
    this.formData.guests = guests;
  }

  setVenue(venue: 'Private Patio' | 'Red Room') {
    this.formData.venue = venue;
  }

  setName(name: string) {
    this.formData.name = name;
  }

  setEmail(email: string) {
    this.formData.email = email;
  }

  setPhone(phone: string) {
    this.formData.phone = phone;
  }

  setMessage(message: string) {
    this.formData.message = message;
  }

  // Navigate between steps
  nextStep() {
    this.currentStep = 2;
  }

  previousStep() {
    this.currentStep = 1;
  }

  // Submit the reservation form
  async submitForm() {
    this.isLoading = true;
    try {
      // Combine hour and minute into 24-hour format
      const [selectedHour, meridiem] = this.formData.hour.split(' ');
      let hour = parseInt(selectedHour, 10);
      const minute = this.formData.minute;

      if (meridiem === 'PM' && hour !== 12) {
        hour += 12;
      } else if (meridiem === 'AM' && hour === 12) {
        hour = 0;
      }

      const time = `${hour.toString().padStart(2, '0')}:${minute}`;

      const payload = {
        date: this.formData.date,
        time,
        guests: this.formData.guests,
        venue: this.formData.venue,
        name: this.formData.name,
        email: this.formData.email,
        phone: this.formData.phone,
        message: this.formData.message,
      };

      await API.post('/api/reservations/', payload);
      this.isLoading = false;
      return true;
    } catch (error) {
      this.isLoading = false;
      console.error('Error submitting reservation:', error);
      return false;
    }
  }

  // Reset the form after successful submission
  resetForm() {
    this.formData = {
      date: '',
      hour: '',
      minute: '',
      guests: 1,
      venue: 'Private Patio',
      name: '',
      email: '',
      phone: '',
      message: '',
    };
    this.currentStep = 1;
  }
}

const reservationStore = new ReservationStore();
export default reservationStore;