// src/components/MenuFunctions/SpecialCategoryHeader.tsx

import React from 'react';
import { Category } from '../../../types';

interface SpecialCategoryHeaderProps {
  category: Category;
  svgSize: string;
}

const SpecialCategoryHeader: React.FC<SpecialCategoryHeaderProps> = ({ category, svgSize }) => {
  return (
    <div className="flex items-center justify-center mb-12 sm:mb-16 md:mb-24 relative">
      {/* Left Line */}
      <div className="flex-grow border-t-2 border-holdrens-red"></div>

      {/* Category Name */}
      <h2 className="mx-4 text-3xl sm:text-4xl md:text-5xl font-paperboard text-center">
        {category.name}
      </h2>

      {/* Right Line */}
      <div className="flex-grow border-t-2 border-holdrens-red"></div>
    </div>
  );
};

export default SpecialCategoryHeader;