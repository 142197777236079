// src/components/LocationSection.tsx

import React from 'react';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';
import AnimatedLink from '../common/AnimatedLink';

const LocationSection: React.FC = () => {
  // Handler to stop event propagation
  const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <section className="text-white py-12">
      {/* Santa Barbara Row */}
      <div
        className="flex flex-col md:flex-row mb-12 cursor-pointer"
        onClick={() => window.location.href = '/locations/sb'}
      >
        <div className="md:w-1/2 flex flex-col items-center justify-center text-center mb-4 md:mb-0">
          <h3 className="font-paperboard text-3xl sm:text-4xl md:text-5xl">SANTA BARBARA</h3>
          <div className="flex items-center mt-4">
            <LocationBubbleSvg className="w-4 h-4 mr-2" />
            <p className="font-satoshi font-medium">
              <AnimatedLink 
                href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+512+State+St,+Santa+Barbara,+CA+93101" 
                targetBlank 
                ariaLabel="Get Directions to Santa Barbara Location" 
                className="block text-lg sm:text-xl md:text-2xl"
                onClick={stopPropagation} // Stop propagation
              >
                512 State Street, Santa Barbara, CA
              </AnimatedLink>
              <AnimatedLink 
                href="tel:+18059653363" 
                ariaLabel="Call Santa Barbara Location" 
                className="block text-lg sm:text-xl md:text-2xl mt-2"
                onClick={stopPropagation} // Stop propagation
              >
                805-965-3363
              </AnimatedLink>
            </p>
          </div>
        </div>
        <div className="md:w-1/2 flex">
          <img 
            src="https://holdrens-bucket.s3.amazonaws.com/holdrensSbInterior.webp" 
            alt="Santa Barbara Location 1" 
            className="w-1/2 h-auto object-cover" 
            loading="lazy" 
          />
          <img 
            src="https://holdrens-bucket.s3.amazonaws.com/holdrensCounter.webp" 
            alt="Santa Barbara Location 2" 
            className="w-1/2 h-auto object-cover" 
            loading="lazy" 
          />
        </div>
      </div>

      {/* Newbury Park Row */}
      <div
        className="flex flex-col md:flex-row cursor-pointer"
        onClick={() => window.location.href = '/locations/np'}
      >
        <div className="md:w-1/2 flex order-2 md:order-1">
          {/* Updated Landscape Images */}
          <img 
            src="https://holdrens-bucket.s3.amazonaws.com/Edited_Wide_Patio.webp" 
            alt="Newbury Park Patio Area" 
            className="w-1/2 h-auto object-cover" 
            loading="lazy" 
          />
          <img 
            src="https://holdrens-bucket.s3.amazonaws.com/Red_Room_Landscape.webp" 
            alt="Newbury Park Red Room" 
            className="w-1/2 h-auto object-cover" 
            loading="lazy" 
          />
        </div>
        <div className="md:w-1/2 flex flex-col items-center justify-center text-center mb-4 md:mb-0 order-1 md:order-2">
          <h3 className="font-paperboard text-3xl sm:text-4xl md:text-5xl">NEWBURY PARK</h3>
          <div className="flex items-center mt-4">
            <LocationBubbleSvg className="w-4 h-4 mr-2" />
            <p className="font-satoshi font-medium">
              <AnimatedLink 
                href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+1714-A,+Newbury+Rd,+Newbury+Park,+CA+91320" 
                targetBlank 
                ariaLabel="Get Directions to Newbury Park Location" 
                className="block text-lg sm:text-xl md:text-2xl"
                onClick={stopPropagation} // Stop propagation
              >
                1714-A Newbury Road, Newbury Park, CA
              </AnimatedLink>
              <AnimatedLink 
                href="tel:+18054981314" 
                ariaLabel="Call Newbury Park Location" 
                className="block text-lg sm:text-xl md:text-2xl mt-2"
                onClick={stopPropagation} // Stop propagation
              >
                805-498-1314
              </AnimatedLink>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationSection;