import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div className="mt-24 space-y-24">
      {/* Trifold Images with About Us Title Below the Center Image */}
      <div className="relative flex flex-wrap justify-center items-center mb-16">
        <img src="https://holdrens-bucket.s3.amazonaws.com/sb-drink.webp" alt="Lavender Cocktail" className="w-1/3 h-auto" />
        <div className="w-1/3 h-auto flex flex-col items-center">
          <img src="https://holdrens-bucket.s3.amazonaws.com/steak-center.webp" alt="Steak is the center of Holdren's Experience" className="w-full h-auto" />
          <h1 className="text-4xl sm:text-6xl font-paperboard text-white mt-8">About Us</h1>
        </div>
        <img src="https://holdrens-bucket.s3.amazonaws.com/np-drink.webp" alt="Hendry's Vodka Stirred" className="w-1/3 h-auto" />
      </div>

      {/* About Us Description */}
      <div className="text-center mx-4 text-xl sm:text-3xl font-paperboard leading-tight">
        HOLDREN'S STEAKS & SEAFOOD HAS BEEN A CULINARY LANDMARK, KNOWN FOR OUR EXCEPTIONAL STEAKS, FRESH SEAFOOD, AND VINTAGE AMBIANCE. WE OFFER A DINING EXPERIENCE THAT CELEBRATES OUR HERITAGE AND COMMITMENT TO EXCELLENCE.
      </div>

      {/* Beautiful SVG */}
      <div className="flex justify-center">
        <img src="https://holdrens-bucket.s3.amazonaws.com/Beautiful-Little-SVG.svg" alt="Beautiful SVG" className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 text-white fill-current" />
      </div>

      {/* Holdrens */}
      <div className="text-center">
        <h1 className="font-paperboard text-6xl sm:text-8xl mb-4">HOLDREN'S</h1>
        <p className="text-center mx-4 text-xl sm:text-2xl font-paperboard leading-tight">
          Holdren's Steaks & Seafood has been serving the finest steaks and freshest seafood since its inception. Our commitment to quality and our rich heritage set us apart, providing our guests with a dining experience that is both exceptional and memorable.
        </p>
      </div>

      {/* Owner Story Section */}
      <div className="flex flex-col md:flex-row items-center mb-16">
        <img src="https://holdrens-bucket.s3.amazonaws.com/professional-chef-preparing-food-kitchen.webp" alt="Professional Chef Preparing Food" className="w-full md:w-1/2 h-auto" />
        <div className="w-full md:w-1/2 p-8">
          <h2 className="font-paperboard text-5xl mb-4">Owner Story — Clay Holdren</h2>
          <p className="text-xl font-lora">
            Stepping into Holdren's Steaks & Seafood is like stepping into an old-time saloon, complete with brown leather booths, a long wooden bar, and dim lighting. This unique ambiance is exactly how Santa Barbara native Clay Holdren envisioned it—a place where he could serve simple, but serious steaks with a touch of nostalgic charm.
            <br /><br />
            Clay's introduction to the restaurant business began at the young age of 14 when he started working as a dishwasher at his uncle Steve's restaurant, Italia Pizzeria, in Fairview Village. Despite his uncle's warning about the immense work involved in running a restaurant, Clay was undeterred. This early experience ignited his dream of owning a restaurant, a dream that he held onto tenaciously.
            <br /><br />
            Throughout college, Clay worked in various Italian restaurants, gaining valuable insights and skills. He spent his days waiting tables in Santa Barbara, where he frequently heard customers lament the lack of a good steakhouse downtown. The only options were Tee-Off on Upper State Street and Lucky's in Montecito, both quite a distance from the heart of the city. This persistent demand sparked Clay's vision for a downtown steakhouse.
            <br /><br />
            Clay's journey was not without its challenges. With critical support from his parents, he embarked on the ambitious project of building Holdren's Steaks & Seafood from the ground up. His father, a contractor, played a pivotal role in the construction. Despite undergoing open-heart surgery, Clay's father's guidance was indispensable. When his father's health issues prevented him from continuing, Clay took over the contracting responsibilities, a task that deeply moved him and solidified his determination. He recalls a moment of overwhelming emotion when reflecting on his father's influence and support, which brought him to tears.
            <br /><br />
            In June 2003, at the age of 29, Clay's dream became a reality as Holdren's Steaks & Seafood opened its doors at 512 State Street. The restaurant was an immediate hit, drawing diners with its inviting atmosphere and exceptional service. Clay attributes his success to the lessons learned from working in Italian restaurants, which helped him create a premier service experience at Holdren's.
            <br /><br />
            Clay's pride in his establishment is evident. He fondly remembers customers not just by their last visit, but equally by the kids and grandkids who have dined there over the years. One of his guiding principles is simple: "We want people to be full and happy when they leave." This commitment to customer satisfaction is reflected in the restaurant's offerings, from steaks and fresh fish to pork chops and house cioppino, all served with a first course and a side, old-school style.
            <br /><br />
            As a prominent figure in Santa Barbara's restaurant scene, Clay is responsible for developing the wine and cocktail programs, creating menus, and educating staff. Beyond restaurant management, Clay is also known for his interest in wine, golf, and sports.
            <br /><br />
            Clay is actively involved in the Santa Barbara community and has been vocal about local issues. He supports maintaining the State Street pedestrian promenade, citing benefits such as improved health and safety, economic vitality, and community well-being. Clay believes the promenade creates a safer environment for walking and boosts local businesses by attracting more foot traffic.
            <br /><br />
            Family has always been at the heart of Clay's journey. His parents were not only crucial in the restaurant's inception but also actively involved in its early days. His mother worked as the hostess, adding a personal touch to the guest experience. Clay's dedication to family extends to his own life as well. Three months after opening the restaurant, Clay married his girlfriend Kristina, and they now have two sons, Jack and Chase. "Becoming a father is the best thing that's ever happened to me," Clay says. "I love those two boys like nothing else."
            <br /><br />
            Clay and his parents expanded their venture by opening a second location in Newbury Park several years ago, driven by their shared vision. Despite the challenges of managing multiple locations, Clay's dedication to maintaining high standards has never wavered.
            <br /><br />
            In October 2023, Holdren's Steaks & Seafood celebrated its 20th anniversary, marking two decades of serving the Santa Barbara community. This milestone underscores Clay Holdren's long-standing presence and influence in Santa Barbara's culinary landscape.
            <br /><br />
            With a flair for steaks, a dedication to service, and a commitment to quality food and wine, Clay Holdren has created a dining experience that fills a niche in downtown Santa Barbara. His story is one of passion, perseverance, and family values, making Holdren's Steaks & Seafood not just a restaurant, but a cherished part of the community.
          </p>
        </div>
      </div>

      {/* Chef Section with Image on Right */}
      <div className="flex flex-col md:flex-row-reverse items-center mb-16">
      <img src="https://holdrens-bucket.s3.amazonaws.com/chef.webp" alt="Chef Cooking Spaghetti" className="w-full md:w-1/2 h-auto" />
        <div className="w-full md:w-1/2 p-8">
          <h2 className="font-paperboard text-5xl mb-4">Head Chef - Guillermo Gil</h2>
          <p className="text-xl font-lora">
            From the day Holdren's Steaks & Seafood opened its doors, our chef has been at the heart of the kitchen, bringing a rich tapestry of culinary experience and passion to every dish he creates. Working alongside Clay Holdren for over 25 years, their partnership has been integral to the restaurant's success. His journey into the culinary world began in his childhood, sparked by the excitement of exploring diverse flavors and combinations in food. This passion for variety and taste set him on a path that would blend his Mexican heritage with the precision of Japanese culinary arts.
            <br /><br />
            Before joining Holdren's, our chef honed his skills at Azuma, a highly-rated Japanese restaurant, for a decade. It was there that he developed an eye for beauty and precision in cooking. He admired the confident and waste-free butchery techniques of Japanese chefs, transforming beef and fish into works of art. This meticulous attention to detail and aesthetic presentation became a cornerstone of his culinary philosophy.
            <br /><br />
            At Holdren's, he found a place where he could blend his influences from Japanese and Mexican cuisines to create a unique and exquisite dining experience. He takes immense pride in the compliments he receives from guests, often moved by their direct expressions of gratitude and occasional gestures like tipping the staff or buying them drinks. One memorable story that still gives him chills is of two ladies in their 50s who, despite personal ordeals, found joy and solace in their meal at Holdren's, forgetting their troubles for a while.
            <br /><br />
            The chef's favorite part of his job is the people - both his team and the guests. The open design of the kitchen allows him to see all the tables in the restaurant, and for the guests to witness the chefs perfecting their craft. This transparency and connection with the diners are vital to him, ensuring that every dish meets the highest standards.
            <br /><br />
            Dedicated to continuous improvement, he reviews the evening's successes and guest recommendations with his staff each night. Early in his career at Holdren's, he innovatively addressed a common issue with the filet cut by removing the fatty section, resulting in perfect filets every time. This attention to detail extends to using premium cuts for dishes like steak bites and the French dip, which have become guest favorites due to their superior quality.
            <br /><br />
            A local of Santa Barbara, the chef's favorite cut of beef is the Rib Eye, though he prefers it cooked through, finding medium rare too fatty for his taste. His journey at Holdren's began with the influence of his first chef, Craig Baldi, and was solidified by the encouragement of Clay Holdren, the reason he joined the team.
            <br /><br />
            Every day, our chef steps into the kitchen excited to create, inspired by the blend of flavors from his past and the joyful reactions of the guests. His story is one of passion, precision, and an unwavering commitment to excellence, making Holdren's Steaks & Seafood the finest dining experience in Santa Barbara.
          </p>
        </div>
      </div>  
    </div>)
  }

export default AboutUs;