// src/components/ReadyToBook.tsx
import React from "react";
import beautifulSvg from "../../assets/images/Beautiful-Little-SVG.svg";

const ReadyToBook: React.FC = () => {
  return (
    <div className="bg-black text-white py-8 sm:py-12 text-center px-4 sm:px-8">
      <h2 className="text-3xl sm:text-4xl font-paperboard mb-4 sm:mb-8">READY TO BOOK YOUR EVENT?</h2>
      <p className="text-lg sm:text-xl font-paperboard mb-2 sm:mb-4">Contact us today at</p>
      <p className="text-3xl sm:text-5xl font-paperboard text-holdrens-gold">805-455-2711</p>
      <p className="text-lg sm:text-xl font-paperboard mt-2 sm:mt-4">To discuss your catering needs. Our team is here to help you plan and execute a perfect event.</p>
      <img 
        src={beautifulSvg} 
        alt="Beautiful SVG" 
        className="w-12 h-12 mx-auto mt-4 sm:mt-8 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 text-white fill-current" 
      />
    </div>
  );
};

export default ReadyToBook;