import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IoIosArrowForward } from 'react-icons/io';
import ElegantLine from '../../assets/images/ElegantLine.svg';

const underlineVariants = {
  hidden: { width: 0 },
  visible: { width: '100%' }
};

const MenuSection: React.FC = () => (
  <section className="text-center text-white py-8 sm:py-12 md:py-16 lg:py-24">
    <h3 className="font-paperboard text-4xl sm:text-6xl md:text-7xl lg:text-8xl mb-4">Menu</h3>
    <div className="flex justify-center mb-6">
      <img src={ElegantLine} alt="Elegant Line" className="w-20 sm:w-24 md:w-32 lg:w-36" />
    </div>
    <p className="font-paperboard text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 leading-tight px-4 sm:px-8">
    Discover our carefully curated menu at Holdren's. From our award-winning steak
      <br className="leading-none" />
      to our cocktails and fresh seafood, we have something for everybody.
    </p>
    <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8">
      <motion.div
        whileHover="visible"
        initial="hidden"
        className="relative py-2 px-4 bg-black bg-opacity-25 rounded-md hover:bg-opacity-50 transition-all flex items-center justify-center"
      >
        <NavLink to="/menus/sb" className="font-paperboard text-2xl sm:text-3xl md:text-4xl text-white">
          Santa Barbara
        </NavLink>
        <motion.span
          className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
          variants={underlineVariants}
          transition={{ duration: 0.3 }}
        />
        <span className="sm:hidden ml-2">
          <IoIosArrowForward className="text-holdrens-gold" />
        </span>
      </motion.div>
      <motion.div
        whileHover="visible"
        initial="hidden"
        className="relative py-2 px-4 bg-black bg-opacity-25 rounded-md hover:bg-opacity-50 transition-all flex items-center justify-center"
      >
        <NavLink to="/menus/np" className="font-paperboard text-2xl sm:text-3xl md:text-4xl text-white tracking-tighter">
          Newbury Park
        </NavLink>
        <motion.span
          className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
          variants={underlineVariants}
          transition={{ duration: 0.3 }}
        />
        <span className="sm:hidden ml-2">
          <IoIosArrowForward className="text-holdrens-gold" />
        </span>
      </motion.div>
    </div>
  </section>
);

export default MenuSection;