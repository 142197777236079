import React from 'react';
import beautifulSvg from '../../assets/images/Beautiful-Little-SVG.svg';
import heritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import OpenTableWidget from './OpenTableWidget';

const AboutSection: React.FC = () => {
  return (
    <section className="text-center text-white py-16 sm:py-24 md:py-32 lg:py-48">
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-paperboard text-center mb-8 sm:mb-12">
        RESERVE YOUR TABLE IN A 
        <br className="leading-none" />
        DINING EXPERIENCE LIKE NO OTHER
      </h2>
      
      <div className="w-full max-w-4xl mx-auto relative">
        <div className="relative w-full max-h-104 md:max-h-88 lg:max-h-52">
          <OpenTableWidget theme='wide' />
        </div>
      </div>

      <img 
        src={beautifulSvg} 
        alt="Beautiful SVG" 
        className="w-12 h-12 mx-auto mb-6 sm:w-16 sm:h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40"
      />

      <p className="text-lg sm:text-2xl md:text-3xl lg:text-5xl font-paperboard mx-auto leading-tight px-4 sm:px-8">
        HOLDREN'S STEAKS & SEAFOOD HAS BEEN A CULINARY 
        <br className="leading-none" />
        LANDMARK, KNOWN FOR OUR EXCEPTIONAL STEAKS, 
        <br className="leading-none" />
        FRESH SEAFOOD, AND VINTAGE AMBIANCE. 
        <br className="leading-none" />
        WE OFFER A DINING EXPERIENCE THAT CELEBRATES OUR 
        <br className="leading-none" />
        HERITAGE AND COMMITMENT TO EXCELLENCE.
      </p>

      <img 
        src={heritageInfinity} 
        alt="Heritage Infinity SVG" 
        className="w-6 h-6 mx-auto my-6 sm:w-8 sm:h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20"
      />
    </section>
  );
};

export default AboutSection;