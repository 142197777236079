import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'About Us', path: '/about-us' },
  {
    name: 'Menus',
    path: '/menus',
    subItems: [
      { name: 'Santa Barbara Menu', path: '/menus/sb' },
      { name: 'Newbury Park Menu', path: '/menus/np' },
    ],
  },
  { name: 'Catering', path: '/catering' },
  {
    name: 'Locations',
    path: '/locations',
    subItems: [
      { name: 'Santa Barbara', path: '/locations/sb' },
      { name: 'Newbury Park', path: '/locations/np' },
    ],
  },
  { name: 'Contact Us', path: '/contact-us' },
];

const underlineVariants = {
  hidden: { scaleX: 0 },
  visible: { scaleX: 1 },
};

const dropdownVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
};

interface NavLinksProps {
  dropUp?: boolean;
}

const NavLinks: React.FC<NavLinksProps> = ({ dropUp = false }) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = (name: string) => {
    setHoveredItem(name);
    if (name === 'Menus' || name === 'Locations') {
      setActiveDropdown(name);
    }
  };

  const handleMouseLeave = (name: string) => {
    setHoveredItem(null);
    if (name === 'Menus' || name === 'Locations') {
      if (dropdownRef.current) {
        dropdownRef.current = null;
      }
      setActiveDropdown(null);
    }
  };

  return (
    <nav className="hidden md:flex space-x-8 ml-auto font-paperboard text-white">
      {menuItems.map((item) => (
        <div
          key={item.name}
          className="relative"
          onMouseEnter={() => handleMouseEnter(item.name)}
          onMouseLeave={() => handleMouseLeave(item.name)}
        >
          <NavLink to={item.path} className="relative">
            {({ isActive }) => (
              <div className="relative">
                <span className="relative z-10 [wordSpacing:0rem]">{item.name}</span>
                <motion.span
                  className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
                  initial="hidden"
                  animate={(hoveredItem === item.name || isActive) ? 'visible' : 'hidden'}
                  variants={underlineVariants}
                  transition={{ duration: 0.3 }}
                />
              </div>
            )}
          </NavLink>
          {item.subItems && (
            <AnimatePresence>
              {activeDropdown === item.name && (
                <motion.div
                  ref={dropdownRef}
                  className={`absolute ${dropUp ? 'bottom-full' : 'top-full'} mt-2 bg-black text-white rounded-md shadow-lg`}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  custom={dropUp}
                  variants={dropdownVariants}
                  transition={{ duration: 0.3 }}
                >
                  {item.subItems.map((subItem) => (
                    <NavLink
                      key={subItem.name}
                      to={subItem.path}
                      className="block px-4 py-2 hover:bg-gray-700"
                    >
                      {subItem.name}
                    </NavLink>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </div>
      ))}
    </nav>
  );
};

export default NavLinks;