// src/components/Special/InformationalNote.tsx

import React from 'react';
import reservationStore from '../../../stores/ReservationsStore';
import { observer } from 'mobx-react-lite';

const InformationalNote: React.FC = observer(() => {
  const guests = reservationStore.formData.guests;

  return (
    <div className="mt-4 text-xs text-gray-600 text-center">
      <p>
        * Reservation requests are subject to availability. Pricing varies based on date and time.
      </p>
      {guests > 40 && (
        <p className="mt-2">
          For parties over 40 guests, you may need to book the entire restaurant, combine venues, or consider our catering
          service. Our Event Coordinator will be in touch to discuss alternative options with you.
        </p>
      )}
    </div>
  );
});

export default InformationalNote;