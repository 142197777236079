import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import categoryStore from "../../stores/CategoryStore";
import { Tooltip } from "react-tooltip";

interface CategoryListProps {
  menu: string;
  onCategoryClick: (categoryId: number) => void;
}

const underlineVariants = {
  hidden: { scaleX: 0 },
  visible: { scaleX: 1 },
};

const CategoryList: React.FC<CategoryListProps> = observer(({ menu, onCategoryClick }) => {
  const [clickedCategory, setClickedCategory] = useState<number | null>(null);
  const [hoveredCategory, setHoveredCategory] = useState<number | null>(null);

  useEffect(() => {
    categoryStore.fetchCategoriesByMenu(menu);
  }, [menu]);

  if (categoryStore.loading) {
    return <div>Loading...</div>;
  }

  if (categoryStore.error) {
    return <div>Error: {categoryStore.error}</div>;
  }

  const handleClick = (id: number) => {
    setClickedCategory(id);
    onCategoryClick(id);
  };

  const handleMouseEnter = (id: number) => {
    setHoveredCategory(id);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  return (
    <div className="sticky top-0 z-10 bg-black py-4 shadow-lg"> {/* Sticky Container */}
      <div className="p-4 pt-8 flex justify-center">
        <ul className="flex flex-wrap justify-center gap-4 max-w-5xl list-none">
          {categoryStore.categories.map((category) => (
            <li
              key={category.id}
              className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-paperboard cursor-pointer relative flex items-center"
              onClick={() => handleClick(category.id)}
              onMouseEnter={() => handleMouseEnter(category.id)}
              onMouseLeave={handleMouseLeave}
              data-tooltip-id={`tooltip-${category.id}`} // Unique tooltip ID for each item
              data-tooltip-content="Click to navigate to this section" // Tooltip text
            >
              {/* Custom Bullet Point */}
              <motion.div
                className="flex items-center justify-center mr-2 w-3 h-3 rounded-full"
                style={{ backgroundColor: clickedCategory === category.id ? "#AE9048" : "#ffffff" }}
                animate={{ scale: hoveredCategory === category.id || clickedCategory === category.id ? 1.2 : 1 }}
                transition={{ duration: 0.3 }}
              />
              {/* Category Name */}
              <motion.span
                initial={{
                  color: clickedCategory === category.id ? "#AE9048" : "#ffffff",
                }}
                animate={{
                  color: clickedCategory === category.id ? "#AE9048" : "#ffffff",
                  textShadow: hoveredCategory === category.id ? "0px 0px 5px rgba(174, 144, 72, 0.5)" : "none",
                }}
                transition={{ duration: 0.3 }}
                className="relative inline-block px-2 py-1 hover:text-holdrens-gold transition duration-300 ease-in-out"
              >
                {category.name}
              </motion.span>
              <motion.div
                className="absolute left-0 right-0 bottom-0 h-1 bg-holdrens-red"
                initial="hidden"
                animate={(hoveredCategory === category.id || clickedCategory === category.id) ? "visible" : "hidden"}
                variants={underlineVariants}
                transition={{ duration: 0.3 }}
              />
              {/* Tooltip for this category */}
              <Tooltip id={`tooltip-${category.id}`} place="top" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default CategoryList;