import React from 'react';
import { NavLink } from 'react-router-dom';

interface CateringSectionProps {
  offset?: number; // optional prop for offset
}

const CateringSection: React.FC<CateringSectionProps> = ({ offset = 0 }) => {
  return (
    <section
      className="relative text-white py-12 bg-cover bg-center min-h-[55vh]"
      style={{ backgroundImage: `url(https://holdrens-bucket.s3.amazonaws.com/catering4.webp)` }}
    >
      <div className="absolute left-4 sm:left-12 top-4 sm:top-1/4" style={{ top: `${offset + 8}vh` }}>
        <div className="bg-black bg-opacity-50 p-4 sm:p-8 rounded-lg">
          <h3 className="font-paperboard text-3xl sm:text-5xl md:text-6xl lg:text-7xl mb-4 sm:mb-6 lg:mb-12">Catering</h3>
          <p className="font-paperboard text-lg sm:text-2xl leading-tight mb-4 sm:mb-6">
            Our catering menu features a variety of 
            <br className="leading-none"/> options to suit any occasion. From appetizers 
            <br className="leading-none"/> to main courses and desserts, we offer 
            <br className="leading-none"/> customizable packages to meet your needs.
          </p>
          {!offset ? (
            <NavLink to="/catering" className="font-paperboard text-sm sm:text-xl inline-block bg-white text-black border border-black px-2 sm:px-4 py-1 sm:py-2">
              Menu
            </NavLink>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default CateringSection;