// src/components/EventSection.tsx

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Slider from 'react-slick';
import heritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import { Link } from 'react-router-dom';
import eventStore, { Event } from '../../stores/EventStore';
import { formatTitle, formatDate, formatTime } from '../../utils';
import { parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const timeZone = 'America/Los_Angeles';

interface EventSectionProps {
  locationName?: string;
}

const EventSection: React.FC<EventSectionProps> = ({ locationName }) => {
  useEffect(() => {
    eventStore.fetchEvents(locationName);
  }, [locationName]);

  // Determine if there's only one event
  const isSingleEvent = useMemo(() => eventStore.events.length <= 1, [eventStore.events]);

  // Slider settings with conditional adjustments
  const sliderSettings = useMemo(() => ({
    dots: !isSingleEvent,
    infinite: !isSingleEvent && eventStore.events.length > 1, // Ensure infinite only if more than one event
    speed: 500,
    autoplay: !isSingleEvent && eventStore.events.length > 1,
    autoplaySpeed: 5000, // 5 seconds
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    adaptiveHeight: false, // Disable adaptive height for consistent slider height
    arrows: !isSingleEvent, // Hide arrows if only one event
  }), [isSingleEvent, eventStore.events.length]);

  if (eventStore.loading) {
    return (
      <section className="text-center text-white pt-16 sm:pt-24 md:pt-32 lg:pt-48 bg-black">
        <h2 className="text-4xl sm:text-5xl md:text-6xl font-paperboard uppercase tracking-wide mb-8 sm:mb-12">
          UPCOMING EVENTS
        </h2>
        <p>Loading events...</p>
      </section>
    );
  }

  if (eventStore.error) {
    return (
      <section className="text-center text-white pt-16 sm:pt-24 md:pt-32 lg:pt-48 bg-black">
        <h2 className="text-4xl sm:text-5xl md:text-6xl font-paperboard uppercase tracking-wide mb-8 sm:mb-12">
          UPCOMING EVENTS
        </h2>
        <p>{eventStore.error}</p>
      </section>
    );
  }

  // If there are no events, you might want to display a different message
  if (eventStore.events.length === 0) {
    return (
      <section className="text-center text-white pt-16 sm:pt-24 md:pt-32 lg:pt-48 bg-black">
        <h2 className="text-4xl sm:text-5xl md:text-6xl font-paperboard uppercase tracking-wide mb-8 sm:mb-12">
          UPCOMING EVENTS
        </h2>
        <p>No upcoming events at the moment.</p>
      </section>
    );
  }

  return (
    <section className="text-center text-white pt-16 sm:pt-24 md:pt-32 lg:pt-48 bg-black">
      {/* Section Title */}
      <h2 className="text-4xl sm:text-5xl md:text-6xl font-paperboard uppercase tracking-wide mb-8 sm:mb-12">
        UPCOMING EVENTS
      </h2>

      {/* Event Slider */}
      <div className="w-full max-w-4xl mx-auto relative">
        <Slider {...sliderSettings}>
          {eventStore.events.map((event: Event) => {
            // Parse dates and times with timezone handling
            const now = new Date();

            const startDateTimeString = `${event.start_date}T${event.start_time}`;
            const endDateTimeString = `${event.end_date}T${event.end_time}`;

            const startDateTimeUtc = parseISO(startDateTimeString);
            const endDateTimeUtc = parseISO(endDateTimeString);

            const startDateTime = toZonedTime(startDateTimeUtc, timeZone);
            const endDateTime = toZonedTime(endDateTimeUtc, timeZone);

            const nowInTimeZone = toZonedTime(now, timeZone);

            let displayStartDate: Date = startDateTime;

            if (startDateTime < nowInTimeZone && nowInTimeZone <= endDateTime) {
              // Event is ongoing, display current date as start date
              displayStartDate = nowInTimeZone;
            } else if (endDateTime < nowInTimeZone) {
              // Event has ended, skip displaying
              return null;
            }

            // Determine if the event is a single-day event
            const isSingleDayEvent = event.start_date === event.end_date;

            return (
              <div key={event.id} className="px-4">
                {/* Slide Container with Flexbox */}
                <div className="flex flex-col justify-center items-center min-h-[400px]">
                  {/* Event Heading */}
                  <h3 className="text-3xl sm:text-4xl md:text-5xl font-paperboard mb-4">
                    {formatTitle(event.title)}
                  </h3>

                  {/* Subheading */}
                  <p className="text-lg sm:text-2xl md:text-3xl lg:text-4xl font-paperboard mx-auto leading-tight px-4 sm:px-8 mb-4">
                    {event.location.name}
                  </p>

                  {/* Event Details */}
                  <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-satoshi mx-auto leading-relaxed px-4 sm:px-8 mb-6 max-w-prose text-center">
                    {event.description}
                    <br />
                    <br />
                    {isSingleDayEvent ? (
                      <>
                        <strong>{formatDate(displayStartDate)}</strong>, from{' '}
                        <strong>{formatTime(event.start_time)}</strong> to{' '}
                        <strong>{formatTime(event.end_time)}</strong>.
                      </>
                    ) : (
                      <>
                        <strong>{formatDate(displayStartDate)}</strong> to{' '}
                        <strong>{formatDate(endDateTime)}</strong>.
                      </>
                    )}
                  </p>

                  {/* Call-to-Action Button */}
                  <Link
                    to={event.link}
                    className="inline-block bg-holdrens-gold text-black font-paperboard text-lg sm:text-xl md:text-2xl px-6 py-3 rounded-sm shadow-lg hover:bg-white transition duration-300"
                  >
                    {event.button_text}
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {/* Decorative SVG */}
      <img
        src={heritageInfinity}
        alt=""
        className="w-6 h-6 mx-auto mt-12 sm:w-8 sm:h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20"
      />
    </section>
  );
};

export default observer(EventSection);