// src/pages/Catering.tsx
import React, { useEffect, useRef } from "react";
import CateringSection from '../common/CateringSection';
import CategoryList from "../common/CategoryList";
import CateringCategorySection from "./CateringCategorySection";
import categoryStore from "../../stores/CategoryStore";
import { observer } from "mobx-react-lite";
import Testimonials from "./Testimonials";
import ReadyToBook from "./ReadyToBook";

const Catering: React.FC = observer(() => {
  const sectionRefs = useRef<Record<number, HTMLDivElement | null>>({});

  useEffect(() => {
    categoryStore.fetchCategoriesByMenu("Catering Menu");
  }, []);

  const handleCategoryClick = (categoryId: number) => {
    const section = sectionRefs.current[categoryId];
    if (section) {
      const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - window.innerHeight * 0.15; // Adjust offset based on viewport height

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <>
      <CateringSection offset={10} />
      <CategoryList menu="Catering Menu" onCategoryClick={handleCategoryClick} />
      <CateringCategorySection sectionRefs={sectionRefs} />
      <Testimonials />
      <ReadyToBook />
    </>
  );
});

export default Catering;