// src/components/MenuItems/MenuItem.tsx
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import menuItemStore from '../../../stores/MenuItemStore';
import { MenuItem as MenuItemType } from '../../../types';
import { motion } from 'framer-motion';
import { formatPrice } from '../../../utils';

interface MenuItemProps {
  categoryId: number;
}

const MenuItem: React.FC<MenuItemProps> = observer(({ categoryId }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = ref.current;

    // If data is already loaded, no need to set up the observer
    if (menuItemStore.getMenuItemsByCategory(categoryId).length > 0) {
      return;
    }

    const observerInstance = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting) {
          // Fetch menu items when the component comes into view
          menuItemStore.fetchMenuItemsForCategories([categoryId]).catch((error) => {
            console.error(`Error loading category ${categoryId}:`, error);
            // Optionally handle error (e.g., display a notification)
          });
          observer.unobserve(entry.target); // Stop observing after fetching
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observerInstance.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observerInstance.unobserve(currentRef);
      }
    };
  }, [categoryId]);

  // If data is not loaded and not loading, the component hasn't been observed yet
  if (
    menuItemStore.getMenuItemsByCategory(categoryId).length === 0 &&
    !menuItemStore.loading[categoryId] &&
    !menuItemStore.error[categoryId]
  ) {
    return <div ref={ref}>Loading...</div>;
  }

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div className="text-red-500">Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);

  // Function to format the price
 

  // Function to group menu items by title, removing size information
  const groupMenuItems = (items: MenuItemType[]) => {
    const groupedItems: Record<string, { title: string; prices: string[]; description: string }> = {};

    items.forEach(item => {
      const key = item.title.replace(/\s*\([^)]*\)/g, '').trim();
      const size = item.title.match(/\([^)]*\)/)?.[0]?.replace(/[()]/g, '') ?? '';
      if (!groupedItems[key]) {
        groupedItems[key] = { title: key, prices: [], description: item.description };
      }
      if (item.price) {
        groupedItems[key].prices.push(`${size ? size : ''} ${formatPrice(item.price)}`); 
      }
    });

    return Object.values(groupedItems);
  };

  const groupedMenuItems = groupMenuItems(menuItems);

  // Function to intelligently split description into sentences without using dangerouslySetInnerHTML
  const splitDescription = (description: string) => {
    const abbreviations = [
      'Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Sr.', 'Jr.', 'St.', 'vs.', 'etc.', 'i.e.', 'e.g.', 'oz.'
    ];
  
    const placeholder = '<ABBR_DOT>';
  
    // Replace periods in abbreviations with a placeholder
    let placeholderDescription = description;
    abbreviations.forEach(abbr => {
      const abbrEscaped = abbr.replace('.', '\\.');
      const regex = new RegExp(abbrEscaped, 'gi'); // Case-insensitive
      const abbrPlaceholder = abbr.replace('.', placeholder);
      placeholderDescription = placeholderDescription.replace(regex, abbrPlaceholder);
    });
  
    // Split the text on periods followed by space and a capital letter
    const sentences = placeholderDescription.split(/\. (?=[A-Z])/);
  
    // Restore the periods in abbreviations
    const restoredSentences = sentences.map(sentence =>
      sentence.replace(new RegExp(placeholder, 'g'), '.')
    );
  
    // Insert <br /> between sentences
    return restoredSentences.map((sentence, idx) => (
      <React.Fragment key={idx}>
        {sentence.trim()}
        {idx < restoredSentences.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    // **Main Container: Increased Max Width and Centered on Larger Screens, Full-Width on Mobile**
    <div className="max-w-7xl mx-auto px-8 sm:px-10 lg:px-16 flex flex-col items-start w-full">
      {groupedMenuItems.map((item, index) => (
        <motion.div
          key={index}
          className="mb-12 flex flex-col w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Title and Price Row */}
          <div className="flex items-center w-full">
            <h4 className="text-lg sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">
              {item.title}
            </h4>
            <div className="flex-grow mx-6 h-0.5 bg-holdrens-red block"></div>
            <div className="text-lg sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words mt-2 sm:mt-0 sm:ml-6">
              {item.prices.join(' | ') || 'MP'}
            </div>
          </div>
          
          {/* Description Below */}
          <div className="mt-6 w-full pl-0 sm:pl-0">
            <p className="font-lora text-lg sm:text-xl leading-none">
              {splitDescription(item.description)}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
});

export default MenuItem;