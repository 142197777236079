// src/components/NewburyParkMenu.tsx
import React from 'react';
import Menu from './Menu';

const NewburyParkMenu: React.FC = () => {
  const SPECIAL_CATEGORIES = ['Special Anniversary Menu']; // Define as needed

  return <Menu menuName="Newbury Park Menu" specialCategories={SPECIAL_CATEGORIES} />;
};

export default React.memo(NewburyParkMenu);