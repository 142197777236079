// src/components/Special/StockYourCellarForm.tsx

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import stockYourCellarStore from '../../../stores/StockYourCellarStore';
import { toast } from 'react-toastify';
import InformationalNote from './InformationalNote';

const StockYourCellarForm: React.FC = observer(() => {
  const [guestError, setGuestError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, email, phone, guests, message } = stockYourCellarStore.formData;

    // Basic validation
    if (!name || !email || !phone || !guests || !message) {
      toast.error('Please fill out all required fields.');
      return;
    }

    // Check number of guests
    if (guests < 1 || guests > 10) {
      setGuestError('Please enter between 1 and 10 guests.');
      return;
    } else {
      setGuestError('');
    }

    await stockYourCellarStore.submitForm();
  };

  // Handle guest number input to enforce max of 10
  const handleGuestsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 10) {
      setGuestError('Please enter no more than 10 guests.');
    } else {
      setGuestError('');
    }
    stockYourCellarStore.setGuests(value);
  };

  return (
    <div className="relative text-black p-4 sm:p-6 lg:p-8 mt-[15vh]">
      <h2 className="text-5xl font-paperboard text-center mb-6 text-holdrens-gold">
       <span className=''>Stock Your Cellar</span> 
        <br/>
        <br/>
        Charity Wine Tasting Event
        <br/>
      </h2>

      {/* Event Advertisement Image */}
      <div className="flex justify-center mb-6">
        <img
          src="https://holdrens-bucket.s3.amazonaws.com/StockYourCellar2024.webp"
          alt="Stock Your Cellar Event"
          className="w-full max-w-md object-cover rounded-md"
        />
      </div>

      {/* Display Success or Waitlist Messages */}
      {stockYourCellarStore.submissionStatus === 'confirmed' && (
        <div className="text-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
          <strong className="font-bold">Reservation Confirmed!</strong>
          <p className="mt-2">Thank you for your reservation. Our coordinator will contact you soon.</p>
        </div>
      )}

      {stockYourCellarStore.submissionStatus === 'waitlisted' && (
        <div className="text-center bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-6">
          <strong className="font-bold">Added to Waitlist</strong>
          <p className="mt-2">{stockYourCellarStore.waitlistMessage}</p>
        </div>
      )}

      {/* Reservation Form */}
      {stockYourCellarStore.submissionStatus === null && (
        <form onSubmit={handleSubmit} className="space-y-6 max-w-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
            {/* Fixed Date (Hidden Field) */}
            <input
              type="hidden"
              name="date"
              value="2024-11-09"
              onChange={() => {}}
            />

            {/* Name */}
            <div className="flex flex-col">
              <label htmlFor="name" className="block text-sm font-paperboard mb-1 text-holdrens-gold">
                Name<span className="text-holdrens-red">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
                value={stockYourCellarStore.formData.name}
                onChange={(e) => stockYourCellarStore.setName(e.target.value)}
                required
              />
            </div>

            {/* Email */}
            <div className="flex flex-col">
              <label htmlFor="email" className="block text-sm font-paperboard mb-1 text-holdrens-gold">
                Email<span className="text-holdrens-red">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
                value={stockYourCellarStore.formData.email}
                onChange={(e) => stockYourCellarStore.setEmail(e.target.value)}
                required
              />
            </div>

            {/* Phone Number */}
            <div className="flex flex-col">
              <label htmlFor="phone" className="block text-sm font-paperboard mb-1 text-holdrens-gold">
                Phone Number<span className="text-holdrens-red">*</span>
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
                value={stockYourCellarStore.formData.phone}
                onChange={(e) => stockYourCellarStore.setPhone(e.target.value)}
                required
              />
            </div>

            {/* Number of Guests */}
            <div className="flex flex-col">
              <label htmlFor="guests" className="block text-sm font-paperboard mb-1 text-holdrens-gold">
                Number of Guests<span className="text-holdrens-red">*</span>
              </label>
              <input
                type="number"
                id="guests"
                name="guests"
                min="1"
                max="10"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
                value={stockYourCellarStore.formData.guests}
                onChange={handleGuestsChange}
                required
              />
              {guestError && <p className="text-xs text-holdrens-red mt-1">{guestError}</p>}
              {!guestError && (
                <p className="text-xs text-gray-500 mt-1">
                  Maximum of 10 guests per reservation. For larger parties, please call{' '}
                  <a href="tel:8054981314" className="text-holdrens-red underline">
                    805-498-1314
                  </a>{' '}
                  (Newbury Park location).
                </p>
              )}
            </div>

            {/* Message */}
            <div className="flex flex-col col-span-1 md:col-span-2">
              <label htmlFor="message" className="block text-sm font-paperboard mb-1 text-holdrens-gold">
                Message<span className="text-holdrens-red">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
                value={stockYourCellarStore.formData.message}
                onChange={(e) => stockYourCellarStore.setMessage(e.target.value)}
                required
              ></textarea>
            </div>
          </div>

          {/* Informational Note */}
          <InformationalNote />

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="px-8 py-3 bg-holdrens-red text-white font-paperboard font-semibold hover:bg-holdrens-gold hover:text-black transition-colors"
              disabled={stockYourCellarStore.isLoading}
            >
              {stockYourCellarStore.isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
});

export default StockYourCellarForm;