// src/components/MenuItems/AnniversarySpecials.tsx

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import menuItemStore from "../../../stores/MenuItemStore";
import categoryStore from "../../../stores/CategoryStore"; // Import CategoryStore
import { MenuItem as MenuItemType, Category } from "../../../types";
import { motion } from "framer-motion";
import HeritageInfinity from "../../../assets/images/Heritage-Infinity.svg"; // Import the SVG

interface AnniversarySpecialsProps {
  categoryId: number;
}

const AnniversarySpecials: React.FC<AnniversarySpecialsProps> = observer(({ categoryId }) => {
  useEffect(() => {
    menuItemStore.fetchMenuItemsByCategory(categoryId);
  }, [categoryId]);

  // Fetch the category object to access notes
  const category: Category | undefined = categoryStore.categories.find(cat => cat.id === categoryId);

  // Handle loading state
  if (menuItemStore.loading[categoryId]) {
    return (
      <div className="flex justify-center items-center space-x-2 text-lg sm:text-xl md:text-2xl">
        <svg
          className="animate-spin h-8 w-8 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <span>Loading sections...</span>
      </div>
    );
  }

  // Handle error state
  if (menuItemStore.error[categoryId]) {
    return (
      <div className="text-center text-red-500 text-lg sm:text-xl md:text-2xl">
        {`Failed to load menu items. Please try again later.`}
      </div>
    );
  }

  // Get menu items
  const menuItems: MenuItemType[] = menuItemStore.getMenuItemsByCategory(categoryId);

  // Helper function to format price
  const formatPrice = (price?: string | number | null): string => {
    if (price == null || price === '') {
      return 'MP';
    }
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    if (isNaN(numericPrice)) {
      return 'MP';
    }
    return numericPrice % 1 !== 0 ? `$${numericPrice.toFixed(2)}` : `$${numericPrice.toFixed(0)}`;
  };

  return (
    <div className="flex flex-col items-center px-4 mb-8 sm:px-8">
      {/* Container for Menu Items and Notes */}
      <div className="w-full max-w-6xl flex flex-col md:flex-row md:gap-8">
        {/* Right Half: Notes (Order First on Small Screens) */}
        <div className="w-full md:w-1/3 flex flex-col items-center order-first md:order-none mb-12 md:mb-0">
          {/* Top HeritageInfinity SVG */}
          <img
            src={HeritageInfinity}
            alt=""
            aria-hidden="true"
            className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 mb-4"
          />

          {/* Notes */}
          {category && category.notes && category.notes.length > 0 && (
            <div className="flex flex-col items-center text-left">
              {category.notes.map((note, index) => (
                <p key={index} className="text-lg sm:text-xl font-lora mb-4">
                  {note}
                </p>
              ))}
            </div>
          )}

          {/* Bottom HeritageInfinity SVG */}
          <img
            src={HeritageInfinity}
            alt=""
            aria-hidden="true"
            className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 mt-4"
          />
        </div>

        {/* Left Half: Menu Items */}
        <div className="w-full md:w-2/3 flex flex-col items-start">
          {/* Menu Items Header */}
          <h3 className="text-2xl sm:text-3xl font-paperboard mb-6">Entrees</h3>

          {/* Menu Items List */}
          <div className="w-full">
            {menuItems.map((item) => (
              <motion.div
                key={item.id}
                className="mb-12 flex flex-col w-full"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {/* Title and Price Row */}
                <div className="flex items-center w-full">
                  {/* Title */}
                  <h4 className="text-lg sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words flex-shrink-0 text-left min-w-[100px]">
                    {item.title}
                  </h4>

                  {/* Holdrens Red Line Using Border for Better Visibility */}
                  <div className="flex-grow mx-4 border-t-2 border-holdrens-red"></div>
                  {/* Alternatively, using background color and increased height */}
                  {/* <div className="flex-grow mx-4 h-2 bg-holdrens-red"></div> */}

                  {/* Price */}
                  <div className="text-lg sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words flex-shrink-0 min-w-[50px]">
                    {formatPrice(item.price)}
                  </div>
                </div>

                {/* Description Below */}
                <div className="mt-4 w-full pl-0 sm:pl-0">
                  <p className="font-lora text-base sm:text-lg leading-tight text-left">
                    {item.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default AnniversarySpecials;