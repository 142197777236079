// src/components/MenuItems/CombinationAndSidesMenuItem.tsx
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import menuItemStore from '../../../stores/MenuItemStore';
import { MenuItem as MenuItemType } from '../../../types';
import { motion } from 'framer-motion';

interface CombinationAndSidesMenuItemProps {
  categoryId: number;
}

const CombinationAndSidesMenuItem: React.FC<CombinationAndSidesMenuItemProps> = observer(({ categoryId }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const theRef = ref.current;

    // If data is already loaded, no need to observe
    if (menuItemStore.getMenuItemsByCategory(categoryId).length > 0) {
      return;
    }

    const observerInstance = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          menuItemStore.fetchMenuItemsForCategories([categoryId]).catch((error) => {
            console.error(`Error loading category ${categoryId}:`, error);
            // Optionally handle error (e.g., display a notification)
          });
          observerInstance.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (theRef) {
      observerInstance.observe(theRef);
    }

    return () => {
      if (theRef) {
        observerInstance.unobserve(theRef);
      }
    };
  }, [categoryId]);

  // If data is not loaded and not loading, the component hasn't been observed yet
  if (
    menuItemStore.getMenuItemsByCategory(categoryId).length === 0 &&
    !menuItemStore.loading[categoryId] &&
    !menuItemStore.error[categoryId]
  ) {
    return <div ref={ref}>Loading...</div>;
  }

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div className="text-red-500">Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);
  const combinationsItems = menuItems.filter((item) => item.subcategory?.name === 'Combinations');
  const sidesItems = menuItems.filter((item) => item.subcategory?.name === 'Sides');

  const formatPrice = (price: number | string) => {
    const numericPrice = typeof price === 'number' ? price : parseFloat(price);

    if (isNaN(numericPrice)) {
      return 'MP';
    }

    return numericPrice % 1 !== 0 ? `$${numericPrice.toFixed(2)}` : `$${Math.round(numericPrice)}`;
  };

  const groupCombinations = (items: MenuItemType[]) => {
    const groupedItems: Record<string, { title: string; prices: string[] }> = {};

    items.forEach((item) => {
      const key = item.title.replace(/\(\d+ OZ\.\)/i, '').trim();
      if (!groupedItems[key]) {
        groupedItems[key] = { title: key, prices: [] };
      }
      if (item.price) {
        if (item.description) {
          groupedItems[key].prices.push(`${item.description} ${formatPrice(item.price)}`);
        } else {
          groupedItems[key].prices.push(formatPrice(item.price));
        }
      }
    });

    return Object.values(groupedItems);
  };

  const groupedCombinations = groupCombinations(combinationsItems);

  return (
    <div className="flex flex-col items-center text-center px-4 sm:px-0 space-y-24"> {/* Added space-y-24 for vertical spacing between sections */}
      {/* Combinations */}
      <div className="w-full md:w-2/3 mx-auto">
        {groupedCombinations.map((item, index) => (
          <motion.div
            key={index}
            className="mb-6 flex flex-col sm:flex-row justify-between items-center w-full" /* Changed mb-4 to mb-6 for more spacing between items */
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center w-full sm:space-x-6"> {/* Added sm:space-x-6 for horizontal spacing on small screens and above */}
              <h4 className="text-xl sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">
                {item.title}
              </h4>
              <div className="flex-grow h-0.5 bg-holdrens-red block"></div> {/* Removed mx-6 */}
              <div className="text-xl sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words mt-2 sm:mt-0">
                {item.prices.join(' | ') || 'MP'}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      {/* Sides */}
      <div className="w-full md:w-2/3 mx-auto">
        {sidesItems.map((item: MenuItemType) => (
          <motion.div
            key={item.id}
            className="mb-6 flex flex-col sm:flex-row justify-between items-center w-full" /* Changed mb-4 to mb-6 for more spacing between items */
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center w-full sm:space-x-6"> {/* Added sm:space-x-6 for horizontal spacing on small screens and above */}
              <h4 className="text-xl sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">
                {item.title}
              </h4>
              <div className="flex-grow h-0.5 bg-holdrens-red block"></div> {/* Removed mx-6 */}
              <div className="text-xl sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words mt-2 sm:mt-0">
                {item.price != null ? formatPrice(item.price) : 'N/A'}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
});

export default CombinationAndSidesMenuItem;