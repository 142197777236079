// src/components/common/AnimatedLink.tsx

import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedLinkProps {
  href: string;
  children: React.ReactNode;
  ariaLabel?: string;
  targetBlank?: boolean;
  rel?: string;
  className?: string;
  footer?: boolean; // New prop to indicate footer styling
  onClick?: React.MouseEventHandler<HTMLAnchorElement>; // Allow onClick handlers
}

const AnimatedLink: React.FC<AnimatedLinkProps> = ({
  href,
  children,
  ariaLabel,
  targetBlank = false,
  rel = '',
  className = '',
  footer = false, // Default to false
  onClick,
}) => (
  <motion.a
    href={href}
    className={`${footer ? 'text-white hover:text-holdrens-red' : 'text-holdrens-gold hover:text-holdrens-red'} ${className}`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    aria-label={ariaLabel}
    target={targetBlank ? "_blank" : undefined}
    rel={rel || (targetBlank ? "noopener noreferrer" : undefined)}
    onClick={onClick} // Forward the onClick prop
  >
    {children}
  </motion.a>
);

export default AnimatedLink;