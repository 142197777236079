// src/components/Special/ReservationForm.tsx

import React from 'react';
import { observer } from 'mobx-react-lite';
import reservationStore from '../../../stores/ReservationsStore';
import ReservationStepOne from './ReservationStepOne';
import ReservationStepTwo from './ReservationStepTwo';

const ReservationForm: React.FC = observer(() => {
  return (
    <div className="relative text-black min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
      {/* Decorative Elements */}

      <div className="bg-white shadow-lg w-full max-w-4xl p-8 flex flex-col">
        <h2 className="text-3xl font-paperboard text-center mb-6 text-holdrens-red">
          Host Your Holiday Party at Holdren's Newbury Park!
        </h2>

        {reservationStore.currentStep === 1 && <ReservationStepOne />}

        {reservationStore.currentStep === 2 && <ReservationStepTwo />}
      </div>
    </div>
  );
});

export default ReservationForm;