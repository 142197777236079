import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../../../assets/images/Logo.svg'; // Update the logo path as needed

const Logo: React.FC = () => (
  <Link to="/" className="mr-8">
    <div className="relative w-[11.99rem] h-[4.55rem]">
      <img src={LogoImage} alt="Logo" className="absolute inset-0 w-full h-full object-contain" />
    </div>
  </Link>
);

export default Logo;
