// src/components/Footer/Footer.tsx

import React, { useState } from 'react';
import Logo from '../Header/Logo';
import NavLinks from '../Header/NavLinks';
import ReservationButton from '../Header/ReservationButton';
import ReservationDropdown from '../Header/ReservationDropdown';
import fbLogo from '../../../assets/images/fb-logo.svg';
import instagramLogo from '../../../assets/images/instagram-logo.svg';
import beautifulSvg from '../../../assets/images/Beautiful-Little-SVG.svg';
import AnimatedLink from '../../common/AnimatedLink'; // Import AnimatedLink

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Handler to stop event propagation
  const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <footer className="bg-black text-white px-6 py-8 xl:px-24 xl:py-12 relative z-10">
      <div className="flex flex-col items-center xl:flex-row xl:items-start xl:justify-between">
        <div className="flex flex-col items-center xl:flex-row xl:items-start xl:space-y-0 xl:space-x-8 mb-8 xl:mb-0">
          <div className="mb-4 xl:mb-0 xl:mr-12">
            <Logo />
          </div>
          <div className="flex flex-col items-center xl:items-start space-y-4">
            <div className="flex flex-col items-center xl:flex-row xl:items-center xl:space-x-8">
              <NavLinks dropUp />
              <div className="mt-4 xl:mt-0 xl:ml-8 relative hidden md:block">
                <ReservationButton toggleModal={toggleModal} />
              </div>
            </div>
            <div className="flex flex-col items-center xl:flex-row xl:items-center xl:space-x-8 mt-4 xl:mt-0">
              {/* Santa Barbara Location */}
              <div className="text-center xl:text-left">
                <h3 className="font-paperboard">
                  <AnimatedLink
                    href="/locations/sb" // Link to your Santa Barbara location page
                    className="hover:underline"
                    ariaLabel="View Santa Barbara Location Details"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    SANTA BARBARA
                  </AnimatedLink>
                </h3>
                <p className="font-satoshi">
                  <AnimatedLink
                    href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+512+State+St,+Santa+Barbara,+CA+93101"
                    targetBlank
                    ariaLabel="Get Directions to Santa Barbara Location"
                    className="hover:underline"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    512 State Street, Santa Barbara, CA
                  </AnimatedLink>
                  <br />
                  <AnimatedLink
                    href="tel:+18059653363"
                    ariaLabel="Call Santa Barbara Location"
                    className="hover:underline"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    805-965-3363
                  </AnimatedLink>
                </p>
              </div>

              <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 mx-auto" />

              {/* Newbury Park Location */}
              <div className="text-center xl:text-left">
                <h3 className="font-paperboard">
                  <AnimatedLink
                    href="/locations/np" // Link to your Newbury Park location page
                    className="hover:underline"
                    ariaLabel="View Newbury Park Location Details"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    NEWBURY PARK
                  </AnimatedLink>
                </h3>
                <p className="font-satoshi">
                  <AnimatedLink
                    href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+1714-A,+Newbury+Rd,+Newbury+Park,+CA+91320"
                    targetBlank
                    ariaLabel="Get Directions to Newbury Park Location"
                    className="hover:underline"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    1714-A Newbury Road, Newbury Park, CA
                  </AnimatedLink>
                  <br />
                  <AnimatedLink
                    href="tel:+18054981314"
                    ariaLabel="Call Newbury Park Location"
                    className="hover:underline"
                    footer // Set footer flag to true
                    onClick={stopPropagation} // Prevent parent click
                  >
                    805-498-1314
                  </AnimatedLink>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex flex-row items-center xl:flex-col xl:items-start space-x-6 xl:space-x-0 xl:space-y-6">
          <a href="https://www.facebook.com/holdrens.santabarbara/" target="_blank" rel="noopener noreferrer">
            <img src={fbLogo} alt="Facebook" className="w-8 h-8" />
          </a>
          <a href="https://www.instagram.com/holdrens_sb" target="_blank" rel="noopener noreferrer">
            <img src={instagramLogo} alt="Instagram" className="w-8 h-8" />
          </a>
        </div>
      </div>

      {/* Reservation Dropdown Modal */}
      <div className="mt-4 xl:mt-0">
        <ReservationDropdown isModalOpen={isModalOpen} toggleModal={toggleModal} footer />
      </div>
    </footer>
  );
};

export default Footer;