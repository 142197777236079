// src/components/Menus/MenusMenuLinks.tsx

import React from 'react';
import MenuLinks from '../common/MenuLinks';

const MenusMenuLinks: React.FC = () => {
  const links = [
    {
      label: 'Santa Barbara',
      path: '/menus/sb',
      matchPath: (pathname: string) => pathname.startsWith('/menus/sb'),
    },
    {
      label: 'Newbury Park',
      path: '/menus/np',
      matchPath: (pathname: string) => pathname.startsWith('/menus/np'),
    },
  ];

  return <MenuLinks links={links} />;
};

export default MenusMenuLinks;