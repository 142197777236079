// src/components/MenuFunctions/SpecialCategoryFooter.tsx

import React from 'react';
import SvgBreak from '../../../assets/images/Beautiful-Little-SVG.svg'; // Ensure the SVG path is correct

interface SpecialCategoryFooterProps {
  svgSize: string;
}

const SpecialCategoryFooter: React.FC<SpecialCategoryFooterProps> = ({ svgSize }) => {
  return (
    <div className="flex items-center justify-center mb-12 sm:mb-16 md:mb-24 relative">
      {/* Left Line */}
      <div className="flex-grow border-t-2 border-holdrens-red"></div>

      {/* Decorative SVG */}
      <img
        src={SvgBreak}
        alt="Decorative Break SVG"
        className={`mx-4 ${svgSize}`}
      />

      {/* Right Line */}
      <div className="flex-grow border-t-2 border-holdrens-red"></div>
    </div>
  );
};

export default SpecialCategoryFooter;