import React from 'react';
import ElegantLine from '../../assets/images/ElegantLine.svg';

interface MenuHeaderProps {
  locationText: string;
}

const MenuHeader: React.FC<MenuHeaderProps> = ({ locationText }) => {
  return (
    <div className="w-full h-full absolute flex flex-col justify-between">
      <div className="absolute top-[12vh] sm:top-[17vh] left-[4vw]">
        {/* Overlay for Mobile */}
        <div className="block sm:hidden bg-black bg-opacity-50 p-4 rounded-lg">
          <h1 className="font-paperboard text-5xl sm:text-7xl md:text-8xl mb-4">Menu</h1>
          <div className="flex justify-center mb-6">
            <img src={ElegantLine} alt="Elegant Line" className="w-24 sm:w-28 md:w-36" />
          </div>
        </div>

        {/* Normal Title and SVG for larger screens */}
        <div className="hidden sm:block">
          <h1 className="font-paperboard text-5xl sm:text-7xl md:text-8xl mb-4">Menu</h1>
          <div className="flex justify-center mb-6">
            <img src={ElegantLine} alt="Elegant Line" className="w-24 sm:w-28 md:w-36" />
          </div>
        </div>
      </div>
      <div className="absolute bottom-6 sm:bottom-10 w-full text-center">
        <p className="text-lg sm:text-2xl font-lora bg-black bg-opacity-50 inline-block px-3 py-1 sm:px-4 sm:py-2">
          {locationText}
        </p>
      </div>
    </div>
  );
};

export default MenuHeader;