import { makeAutoObservable } from "mobx";
import API from "../api/client";
import { PDFUpload } from "../types";

class PdfStore {
  pdfs: PDFUpload[] = [];
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchPdfs = async () => {
    this.loading = true;
    this.error = null;
    try {
      const response = await API.get<PDFUpload[]>("/api/pdfs/");
      this.pdfs = response.data;
    } catch (error: any) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  };

  getPdfByCategory = (categoryId: number): PDFUpload | undefined => {
    return this.pdfs.find((pdf) => pdf.category === categoryId);
  };
}

const pdfStore = new PdfStore();
export default pdfStore;