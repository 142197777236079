import React from 'react';

interface ReservationButtonProps {
  toggleModal: () => void;
}

const ReservationButton: React.FC<ReservationButtonProps> = ({ toggleModal }) => (
  <button
    onClick={toggleModal}
    className="px-4 py-2 text-2xl font-paperboard transition duration-300 rounded text-black bg-white"
  >
    RESERVATIONS
  </button>
);

export default ReservationButton;