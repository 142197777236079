// src/pages/locations/NewburyParkLocation.tsx
import React from 'react';
import beautifulSvg from '../../assets/images/Beautiful-Little-SVG.svg';
import ElegantLine from '../../assets/images/ElegantLine.svg';
import NewburyParkMenu from '../Menu/NewburyParkMenu';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';
import AnimatedLink from '../common/AnimatedLink';
import EventSection from './EventSection';

const NewburyParkLocation: React.FC = () => {
  return (
    <div className="mt-8 sm:mt-16 md:mt-24 space-y-12 sm:space-y-16 md:space-y-24">
      <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 mx-auto mt-8 text-white fill-current" />
      
      <div className="flex flex-col items-center justify-center mt-4 space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
        <LocationBubbleSvg className="w-8 h-8 mr-2" />
        <p className="font-satoshi font-medium text-center leading-tight">
          <AnimatedLink 
            href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+1714-A,+Newbury+Rd,+Newbury+Park,+CA+91320" 
            targetBlank 
            ariaLabel="Get Directions to Newbury Park Location" 
            className="block text-lg sm:text-xl md:text-2xl"
          >
            1714 Newbury Road, Newbury Park, CA
          </AnimatedLink>
          <AnimatedLink 
            href="tel:+18054981314" 
            ariaLabel="Call Newbury Park Location" 
            className="block text-lg sm:text-xl md:text-2xl mt-2"
          >
            805-498-1314        
          </AnimatedLink>
        </p>
      </div>
      
      <h3 className="font-paperboard text-4xl sm:text-6xl md:text-8xl text-center">NEWBURY PARK</h3>
      <p className="text-center mx-4 text-lg sm:text-2xl md:text-3xl lg:text-3xl font-paperboard leading-tight">
        Welcome to Holdren's Newbury Park, where culinary excellence meets a cozy, inviting atmosphere. 
        <br className='leading-none'/>
        Join us for a meal and experience the best in steak and seafood.
      </p>
      
      <div className="flex flex-col sm:flex-row justify-center items-center mb-16 space-y-4 sm:space-y-0 sm:space-x-4">
        {/* Updated Landscape Images */}
        <img src="https://holdrens-bucket.s3.amazonaws.com/Edited_Wide_Patio.webp" alt="Patio Area" className="w-full sm:w-1/2 h-auto object-cover" loading="lazy" />
        <img src="https://holdrens-bucket.s3.amazonaws.com/Red_Room_Landscape.webp" alt="Red Room" className="w-full sm:w-1/2 h-auto object-cover" loading="lazy" />
      </div>

      {/* Add EventSection for Newbury Park */}
      <EventSection locationName="Newbury Park" />
      
      <div className="text-center">
        <h1 className="font-paperboard text-4xl sm:text-6xl md:text-8xl mb-4">Menu</h1>
        <div className="flex justify-center mb-6">
          <img src={ElegantLine} alt="Elegant Line" className="w-20 sm:w-24 md:w-32 lg:w-36" />
        </div>
      </div>
      
      <NewburyParkMenu />
      <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 mx-auto mt-8 text-white fill-current" />
    </div>
  );
};

export default NewburyParkLocation;