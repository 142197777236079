import React from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import LocationSection from './LocationSection';
import MenuSection from './MenuSection';
import CateringSection from '../common/CateringSection';

const Home: React.FC = () => {
  return (
    <>
      <HeroSection />
      <AboutSection />
      <LocationSection />
      <MenuSection />
      <CateringSection />
    </>
  );
};

export default Home;
