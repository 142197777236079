// src/components/Locations/LocationsMenuLinks.tsx

import React from 'react';
import MenuLinks from '../common/MenuLinks';

const LocationsMenuLinks: React.FC = () => {
  const links = [
    {
      label: 'Santa Barbara',
      path: '/locations/sb',
      matchPath: (pathname: string) => pathname.startsWith('/locations/sb'),
    },
    {
      label: 'Newbury Park',
      path: '/locations/np',
      matchPath: (pathname: string) => pathname.startsWith('/locations/np'),
    },
  ];

  return <MenuLinks links={links} />;
};

export default LocationsMenuLinks;