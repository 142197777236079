// utils/index.tsx

export const formatPrice = (price: string | number | null | undefined) => {
    if (price == null || price === '') {
      return 'MP';
    }
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    if (isNaN(numericPrice)) {
      return 'MP';
    }
    return numericPrice % 1 !== 0 ? `$${numericPrice.toFixed(2)}` : `$${numericPrice.toFixed(0)}`;
  };

/**
 * Formats a title string by converting ordinal suffixes to superscript.
 * Example: "21st ANNIVERSARY" becomes "21<sup>st</sup> ANNIVERSARY"
 * 
 * This function only formats the first occurrence of an ordinal suffix at the beginning of the title.
 * 
 * @param title - The event title string.
 * @returns JSX.Element with formatted title.
 */
export const formatTitle = (title: string): JSX.Element => {
  // Define a regex to match ordinals at the beginning of the string
  const regex = /^(\d+)(st|nd|rd|th)\s+(.+)$/i;
  const match = title.match(regex);

  if (match) {
    const [_, number, suffix, rest] = match;
    return (
      <>
        {number}
        <sup>{suffix.toLowerCase()}</sup> {rest}
      </>
    );
  }

  return <>{title}</>;
};

export function formatTime(timeString: string): string {
  if (!timeString) return '';

  // Split the time string into components
  const timeParts = timeString.split(':');

  // Ensure there are at least two parts (hour and minute)
  if (timeParts.length < 2) return timeString;

  let [hourStr, minuteStr, secondStr] = timeParts;

  // Parse hour and minute
  let hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  // Validate parsed numbers
  if (isNaN(hour) || isNaN(minute)) return timeString;

  // Determine AM or PM
  const ampm = hour >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hour = hour % 12;
  hour = hour === 0 ? 12 : hour; // Handle midnight and noon

  // Format minute with leading zero if necessary
  const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;

  return `${hour}:${formattedMinute} ${ampm}`;
}
/**
 * Formats a date string to "Month Day<sup>Suffix</sup>, Year".
 *
 * @param dateString - The date string in a parseable format (e.g., "2024-11-23").
 * @returns A JSX element with the formatted date.
 */
export function formatDate(dateInput: string | Date): JSX.Element {
  let date: Date;
  if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else {
    date = dateInput;
  }
  if (isNaN(date.getTime())) return <>{dateInput}</>;

  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();

  const suffix = getOrdinalSuffix(day);

  return (
    <>
      {month} {day}
      <sup className="text-xs align-super">{suffix}</sup>, {year}
    </>
  );
}

/**
 * Returns the ordinal suffix for a given day.
 *
 * @param day - The day of the month.
 * @returns The ordinal suffix ("st", "nd", "rd", "th").
 */
function getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}