import React from 'react';
import { MenuItem as MenuItemType } from '../../../types';

interface SpecialCardProps {
  subcategoryName: string;
  items: MenuItemType[];
}

const SpecialCard: React.FC<SpecialCardProps> = ({ subcategoryName, items }) => {
  return (
    <div className="bg-gray-900 rounded-lg shadow-lg p-6 text-center flex flex-col justify-between border border-gray-700 w-full h-full">
      <h3 className="text-2xl font-bold text-redz-red mb-4 uppercase tracking-wide">{subcategoryName}</h3>
      <div className="flex-grow flex flex-col justify-center">
        {items.map(item => (
          <div key={item.id} className="mb-4">
            <h4 className="text-xl font-semibold text-gray-100">{item.title}</h4>
            <p className="text-gray-400 whitespace-pre-line">{item.description}</p>
            {item.price && <p className="text-redz-red text-lg font-bold mt-2">${item.price}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialCard;