import { FC, ReactNode } from "react";
import Footer from "./Footer/Footer";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header/index";

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface RootProps {
  children?: ReactNode;
}

const Root: FC<RootProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen text-white bg-black">
      <Header />
      <div className="flex-grow relative z-0 overflow-x-hidden">
        {children}
      </div>
      <Footer />
      <ToastContainer position="top-center" autoClose={2500} />
      <ScrollRestoration getKey={(location) => {
        // Handle /menus and /menus/* as the same key
        if (location.pathname.startsWith("/menus")) {
          return "/menus";
        }
        if (location.pathname.startsWith("/locations")) {
          return "/locations";
        }
        return location.pathname;
      }} />
    </div>
  );
}

export default Root;