// src/pages/locations/Locations.tsx
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MenuLinks from './MenuLinks';
import AnimatedLink from '../common/AnimatedLink';

const Locations: React.FC = () => {
  const location = useLocation();

  // Determine which images to display based on the current path
  const isSBLocation = location.pathname.includes('/locations/sb');
  const isNPLocation = location.pathname.includes('/locations/np');

  // Image URLs
  const sbImages = [
    "https://holdrens-bucket.s3.amazonaws.com/holdrensSbInterior.webp",
    "https://holdrens-bucket.s3.amazonaws.com/holdrensCounter.webp"
  ];
  const npImages = [
    "https://holdrens-bucket.s3.amazonaws.com/Edited_Wide_Patio.webp",
    "https://holdrens-bucket.s3.amazonaws.com/Red_Room_Landscape.webp"
  ];
  const steakImage = "https://holdrens-bucket.s3.amazonaws.com/steak-center.webp"; // URL for the steak image

  // Choose images based on location
  const images = isSBLocation ? sbImages : isNPLocation ? npImages : [steakImage];

  return (
    <div className="mt-[15vh] sm:mt-[20vh] px-4 sm:px-8">
      <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
        {/* Render images conditionally */}
        <img 
          src={images[0]} 
          alt="Location Interior" 
          className="w-full sm:w-1/2 h-auto object-cover" 
          loading="lazy" 
        />
        {images[1] && (
          <img 
            src={images[1]} 
            alt="Location Interior" 
            className="w-full sm:w-1/2 h-auto object-cover" 
            loading="lazy" 
          />
        )}
      </div>
      <div className="mt-8 sm:mt-16">
        <MenuLinks />
      </div>
      <div className="mt-8 sm:mt-16">
        <Outlet />
      </div>
    </div>
  );
};

export default Locations;