import React from 'react';

const HeroSection: React.FC = () => (
  <section
    className="relative text-white pt-[25vh] bg-cover bg-center"
  >
    <div className="text-center px-4">
      <h1 className="font-paperboard text-4xl sm:text-6xl md:text-8xl mb-4">Contact Us</h1>
      <p className="font-paperboard text-lg sm:text-xl md:text-3xl leading-tight">
        Have a question or need more information? Fill out the form below
        <br className='leading-none'/> 
        and a member of our team will get back to you shortly.
      </p>
    </div>
  </section>
);

export default HeroSection;