// src/components/SantaBarbaraMenu.tsx

import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { reaction as mobxReaction } from 'mobx'; // Correctly imported from 'mobx'
import categoryStore from '../../stores/CategoryStore';
import PdfStore from '../../stores/PdfStore';
import CategoryList from '../common/CategoryList';
import ScrollObserver from './MenuFunctions/ScrollObserver';
import CategorySection from './MenuFunctions/CategorySection';
import HeritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import ElegantLine from '../../assets/images/ElegantLine.svg';
import menuItemStore from '../../stores/MenuItemStore';
import SpecialCategoryHeader from './MenuFunctions/SpecialCategoryHeader';
import SpecialCategoryFooter from './MenuFunctions/SpecialCategoryFooter';

const SantaBarbaraMenu: React.FC = observer(() => {
  const [visibleSections, setVisibleSections] = useState<Set<number>>(new Set());
  const [clickedCategoryId, setClickedCategoryId] = useState<number | null>(null);
  const [requiredCategories, setRequiredCategories] = useState<number[]>([]);
  const [isPreloading, setIsPreloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isScrolling = useRef<boolean>(false);

  // Create a ref map for category sections
  const sectionRefs = useRef<Record<number, HTMLDivElement | null>>({});

  // Define special categories
  const SPECIAL_CATEGORIES = ['21st Anniversary Menu']; // Add more as needed

  useEffect(() => {
    const fetchData = async () => {
      try {
        await categoryStore.fetchCategoriesByMenu('Santa Barbara Menu');
        await PdfStore.fetchPdfs();
      } catch (error: any) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleIntersect = (categoryId: number) => {
    // If a programmatic scroll is happening, do not trigger additional actions
    if (isScrolling.current) return;

    setVisibleSections(prev => new Set(prev).add(categoryId));
  };

  const handleCategoryClick = async (categoryId: number) => {
    setClickedCategoryId(categoryId); // Set clicked category ID
    setIsPreloading(true); // Start preloading
    setErrorMessage(null); // Reset any previous error message

    try {
      // Determine the index of the clicked category
      const categoryIndex = categoryStore.categories.findIndex(cat => cat.id === categoryId);
      if (categoryIndex === -1) {
        throw new Error(`Category with id ${categoryId} not found`);
      }

      // Get all categories up to and including the clicked one
      const categoriesToLoad = categoryStore.categories
        .slice(0, categoryIndex + 2)
        .map(cat => cat.id);

      setRequiredCategories(categoriesToLoad); // Set the required categories

      // Preload all required categories using the menuItemStore
      await menuItemStore.fetchMenuItemsForCategories(categoriesToLoad);

      // Make required sections visible to trigger rendering
      setVisibleSections(prev => new Set([...prev, ...categoriesToLoad]));
    } catch (error: any) {
      console.error('Error preloading categories:', error);
      setErrorMessage('Failed to load sections. Please try again.');
    } finally {
      setIsPreloading(false); // End preloading
    }
  };

  useEffect(() => {
    // Observe when all required categories have finished loading (for IntersectionObserver cases)
    const disposer = mobxReaction(
      () => requiredCategories.map(id => menuItemStore.loading[id]),
      (loadingStates) => {
        if (clickedCategoryId && loadingStates.every(isLoading => !isLoading)) {
          // All required categories have finished loading
          const section = sectionRefs.current[clickedCategoryId];
          if (section) {
            isScrolling.current = true;

            // Calculate the scroll position with existing offset
            const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - window.innerHeight * 0.15;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });

            // Reset the scrolling flag and clickedCategoryId after scroll is likely complete
            setTimeout(() => {
              isScrolling.current = false;
              setClickedCategoryId(null); // Prevent further reactions
            }, 1000); // Adjust as needed based on scroll duration
          }
        }
      }
    );

    return () => {
      disposer(); // Cleanup the reaction when the component unmounts or dependencies change
    };
  }, [clickedCategoryId, requiredCategories]);

  // New useEffect to handle scrolling after sections have rendered
  useEffect(() => {
    if (clickedCategoryId !== null && visibleSections.has(clickedCategoryId)) {
      const section = sectionRefs.current[clickedCategoryId];
      if (section && !menuItemStore.loading[clickedCategoryId] && !menuItemStore.error[clickedCategoryId]) {
        // To ensure the section is fully rendered, use requestAnimationFrame
        requestAnimationFrame(() => {
          isScrolling.current = true;

          // Calculate the scroll position with existing offset
          const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - window.innerHeight * 0.15;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

          // Reset the scrolling flag and clickedCategoryId after scroll is likely complete
          setTimeout(() => {
            isScrolling.current = false;
            setClickedCategoryId(null); // Prevent further reactions
          }, 1000); // Adjust as needed based on scroll duration
        });
      }
    }
  }, [visibleSections, clickedCategoryId]);

  return (
    <div className="mt-8 sm:mt-16 md:mt-24 space-y-12 sm:space-y-16 md:space-y-24">
      <CategoryList menu="Santa Barbara Menu" onCategoryClick={handleCategoryClick} />

      {/* Display loading indicator while preloading */}
      {isPreloading && (
        <div className="flex justify-center items-center space-x-2 text-lg sm:text-xl md:text-2xl">
          <svg
            className="animate-spin h-8 w-8 text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          <span>Loading sections...</span>
        </div>
      )}

      {/* Display error message if preloading fails */}
      {errorMessage && (
        <div className="text-center text-red-500 text-lg sm:text-xl md:text-2xl">
          {errorMessage}
        </div>
      )}

      {/* Render each category section */}
      {categoryStore.categories.map((category, index) => {
        const isOdd = index % 2 === 1;
        const SvgLeft = isOdd ? ElegantLine : HeritageInfinity;
        const SvgRight = isOdd ? ElegantLine : HeritageInfinity;
        const svgSize =
          SvgLeft === HeritageInfinity
            ? 'w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24'
            : 'w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 xl:w-48';

        const isSpecial = SPECIAL_CATEGORIES.includes(category.name);

        return (
          <ScrollObserver
            key={category.id}
            onIntersect={handleIntersect}
            categoryId={category.id}
          >
            <div
              id={`category-${category.id}`}
              data-category-id={category.id}
              className="mb-12 sm:mb-16 md:mb-24"
              ref={el => (sectionRefs.current[category.id] = el)}
            >
              {/* Header */}
              {isSpecial ? (
                <SpecialCategoryHeader category={category} svgSize={svgSize} />
              ) : (
                <div className="text-center mb-12 sm:mb-16 md:mb-24 relative flex flex-col sm:flex-row items-center justify-center">
                  <img
                    src={SvgLeft}
                    alt="Left Decorative SVG"
                    className={`mb-4 sm:mb-0 ${svgSize}`}
                  />
                  <h2 className="text-3xl sm:text-4xl md:text-5xl font-paperboard mx-4">
                    {category.name}
                  </h2>
                  <img
                    src={SvgRight}
                    alt="Right Decorative SVG"
                    className={`mt-4 sm:mt-0 ${svgSize}`}
                  />
                </div>
              )}

              {/* Category Content */}
              <CategorySection
                category={category}
                isVisible={visibleSections.has(category.id)}
              />

              {/* Footer for Special Categories */}
              {isSpecial && (
                <SpecialCategoryFooter svgSize="w-24 h-8 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-44 lg:h-44 xl:w-56 xl:h-" />
              )}
            </div>
          </ScrollObserver>
        );
      })}
    </div>
  );
});

export default React.memo(SantaBarbaraMenu);