// src/components/Special/ReservationStepTwo.tsx

import React from 'react';
import { observer } from 'mobx-react-lite';
import reservationStore from '../../../stores/ReservationsStore';
import { toast } from 'react-toastify';
import InformationalNote from './InformationalNote';

const ReservationStepTwo: React.FC = observer(() => {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, email, phone, message } = reservationStore.formData;

    // Basic validation
    if (!name || !email || !phone || !message) {
      toast.error('Please fill out all required fields.');
      return;
    }

    const success = await reservationStore.submitForm();
    if (success) {
      toast.success('Your request has been submitted! Our Event Coordinator will be in touch shortly.');
      reservationStore.resetForm();
    } else {
      toast.error('There was an error submitting your request. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
        {/* Name */}
        <div className="flex flex-col">
          <label htmlFor="name" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Name<span className="text-holdrens-gold">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
            value={reservationStore.formData.name}
            onChange={(e) => reservationStore.setName(e.target.value)}
            required
          />
        </div>

        {/* Email */}
        <div className="flex flex-col">
          <label htmlFor="email" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Email<span className="text-holdrens-gold">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
            value={reservationStore.formData.email}
            onChange={(e) => reservationStore.setEmail(e.target.value)}
            required
          />
        </div>

        {/* Phone Number */}
        <div className="flex flex-col">
          <label htmlFor="phone" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Phone Number<span className="text-holdrens-gold">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
            value={reservationStore.formData.phone}
            onChange={(e) => reservationStore.setPhone(e.target.value)}
            required
          />
        </div>
      </div>

      {/* Message */}
      <div className="flex flex-col">
        <label htmlFor="message" className="block text-sm font-paperboard mb-1 text-holdrens-red">
          Message<span className="text-holdrens-gold">*</span>
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
          value={reservationStore.formData.message}
          onChange={(e) => reservationStore.setMessage(e.target.value)}
          required
        ></textarea>
      </div>

      {/* Informational Note */}
      <InformationalNote />

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={() => reservationStore.previousStep()}
          className="px-8 py-3 bg-holdrens-gold text-black font-paperboard font-semibold hover:bg-holdrens-red hover:text-white transition-colors"
        >
          Back
        </button>
        <button
          type="submit"
          className="px-8 py-3 bg-holdrens-red text-white font-paperboard font-semibold hover:bg-holdrens-gold hover:text-black transition-colors"
          disabled={reservationStore.isLoading}
        >
          {reservationStore.isLoading ? 'Sending...' : 'Submit'}
        </button>
      </div>
    </form>
  );
});

export default ReservationStepTwo;