// src/components/CateringCategorySection.tsx
import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import categoryStore from "../../stores/CategoryStore";
import MenuItem from "./MenuItem";
import { observer } from "mobx-react-lite";
import HeritageInfinity from '../../assets/images/Heritage-Infinity.svg';

interface CateringCategorySectionProps {
  sectionRefs: React.MutableRefObject<Record<number, HTMLDivElement | null>>;
}

const CateringCategorySection: React.FC<CateringCategorySectionProps> = observer(({ sectionRefs }) => {
  const navigate = useNavigate();

  const getBottomText = (categoryName: string) => {
    const categoriesWithBottomText = [
      "Santa Barbara Buffet",
      "Plated",
      "Additional Plated Options",
    ];
    if (categoriesWithBottomText.includes(categoryName)) {
      return (
        <div className="text-center mt-4 sm:mt-8">
          <p className="text-sm sm:text-base font-paperboard">Includes</p>
          <p className="text-sm sm:text-base font-paperboard">Garlic Bread</p>
          <p className="text-sm sm:text-base font-paperboard">or</p>
          <p className="text-sm sm:text-base font-paperboard">Squaw & Ciabatta</p>
        </div>
      );
    }
    return null;
  };

  const handlePricingClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className="mt-8 sm:mt-16 md:mt-24 px-4 sm:px-8">
      {categoryStore.categories.map((category) => (
        <div
          key={category.id}
          ref={(el) => (sectionRefs.current[category.id] = el)}
          className="mb-24 sm:mb-16 md:mb-24"
        >
          <div className="text-center">
            <motion.p
              className="text-xs sm:text-sm md:text-base text-holdrens-gold font-lora mb-4 cursor-pointer"
              onClick={handlePricingClick}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              Please inquire for pricing
            </motion.p>
            <h2 className="text-2xl sm:text-4xl font-paperboard">{category.name}</h2>
            <img 
              src={HeritageInfinity} 
              alt="Heritage Infinity" 
              className="w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 mx-auto mt-4 sm:mt-8" 
            />
          </div>
          <MenuItem categoryId={category.id} />
          {getBottomText(category.name)}
        </div>
      ))}
    </div>
  );
});

export default CateringCategorySection;