// src/components/ContactInfo.tsx
import React from 'react';
import { ReactComponent as BeautifulSvg } from '../../assets/images/Beautiful-Little-SVG.svg';
import fbLogo from '../../assets/images/fb-logo.svg';
import instagramLogo from '../../assets/images/instagram-logo.svg';
import AnimatedLink from '../common/AnimatedLink';

const ContactInfo: React.FC = () => (
  <div className="flex flex-col justify-center items-center h-full px-4">
    <div className="text-center">
      <BeautifulSvg className="w-24 h-24 sm:w-40 sm:h-40 mb-4 mx-auto transform rotate-180" />
      
      {/* Santa Barbara Section */}
      <div className="mb-8 space-y-4">
        <p className="text-3xl sm:text-5xl font-paperboard leading-tight">Santa Barbara</p>
        <AnimatedLink 
          href="tel:+18059653363" 
          ariaLabel="Call Santa Barbara Location" 
          className="text-3xl sm:text-5xl font-paperboard"
        >
          805-965-3363
        </AnimatedLink>
        <p className="text-lg sm:text-2xl font-satoshi">for reservations and inquiries</p>
        <AnimatedLink 
          href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+512+State+St,+Santa+Barbara,+CA+93101" 
          targetBlank 
          ariaLabel="Get Directions to Santa Barbara Location" 
          className="text-lg sm:text-2xl font-satoshi"
        >
          Get Directions
        </AnimatedLink>
      </div>
      
      {/* Newbury Park Section */}
      <div className="mb-8 space-y-4">
        <p className="text-3xl sm:text-5xl font-paperboard leading-tight">Newbury Park</p>
        <AnimatedLink 
          href="tel:+18054981314" 
          ariaLabel="Call Newbury Park Location" 
          className="text-3xl sm:text-5xl font-paperboard"
        >
          805-498-1314
        </AnimatedLink>
        <p className="text-lg sm:text-2xl font-satoshi">for reservations and inquiries</p>
        <AnimatedLink 
          href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+1714-A,+Newbury+Rd,+Newbury+Park,+CA+91320" 
          targetBlank 
          ariaLabel="Get Directions to Newbury Park Location" 
          className="text-lg sm:text-2xl font-satoshi"
        >
          Get Directions
        </AnimatedLink>
      </div>
      
      {/* Social Media Links */}
      <div className="mt-8 flex justify-center space-x-4">
        <AnimatedLink 
          href="https://www.facebook.com/holdrens.santabarbara/" 
          targetBlank 
          ariaLabel="Holdren's Facebook Page"
          className="flex items-center"
        >
          <img src={fbLogo} alt="Facebook" className="w-6 h-6 sm:w-8 sm:h-8" />
        </AnimatedLink>
        <AnimatedLink 
          href="https://www.instagram.com/holdrens_sb" 
          targetBlank 
          ariaLabel="Holdren's Instagram Page"
          className="flex items-center"
        >
          <img src={instagramLogo} alt="Instagram" className="w-6 h-6 sm:w-8 sm:h-8" />
        </AnimatedLink>
      </div>
      <BeautifulSvg className="w-24 h-24 sm:w-40 sm:h-40 mt-8 mx-auto" />
    </div>
  </div>
);

export default ContactInfo;