import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface OpenTableWidgetProps {
  theme?: string;
}

const OpenTableWidget: React.FC<OpenTableWidgetProps> = ({ theme = 'wide' }) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMediumOrSmaller = useMediaQuery({ query: '(max-width: 820px)' });

  useEffect(() => {
    const selectedTheme = isMediumOrSmaller ? 'tall' : theme;
    const uniqueParam = new Date().getTime();
    const widgetScriptUrl = `https://www.opentable.com/widget/reservation/loader?rid=267466&rid=197626&type=multi&color=3&theme=${selectedTheme}&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&cacheBuster=${uniqueParam}&position=unset`;

    const appendScript = () => {
      const script = document.createElement('script');
      script.src = widgetScriptUrl;
      script.async = true;
      script.id = 'ot-widget-script';

      if (containerRef.current) {
        containerRef.current.innerHTML = '';
        containerRef.current.appendChild(script);
      }
    };

    appendScript();

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [location.pathname, isMediumOrSmaller]);

  return (
    <div ref={containerRef} id="opentable-widget-container" className="opentable-widget w-full flex justify-center items-center">
      {/* Placeholder for OpenTable widget */}
    </div>
  );
};

export default OpenTableWidget;